import { base_url } from '@/utils/BASE_URL'
const TDTTK = '9cac7efeed35edfdd5630ac00ad03784';
// const TDTTK = '346217051e31e0726efe23ecedb1b52b';// 备用

const MAP_SERVE_URL_PRE = base_url.MAP;
const LAYER = {
  // 天地图84
  SatelliteUrl: `http://t3.tianditu.com/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=${TDTTK}`, // 天地图卫星地图
  BaseSourceUrl: `http://t4.tianditu.com/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=${TDTTK}`, // 底图http://mt0.google.cn/vt/lyrs=s&x=0&
  SourceMarkUrl: `http://t3.tianditu.com/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=${TDTTK}`, // 标注图层
  PrivateLayerUrl: '', // 自建图层

  // 天地图02
  TDT_WX: `https://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=${TDTTK}`,
  TDT_LW: `https://t{0-7}.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=${TDTTK}`,
  TDT_JD: `https://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=${TDTTK}`,

  // 高德地图02
  GD_WX: 'http://webst03.is.autonavi.com/appmaptile?style=6&&size=1&style=7&x={x}&y={y}&z={z}',
  GD_LW: 'http://wprd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=8&x={x}&y={y}&z={z}',
  GD_JD: 'http://wprd03.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}'
};

const LAYER_LOCAL = {
  label: `${MAP_SERVE_URL_PRE}/images/label/`, // 标注图层
  vec: `${MAP_SERVE_URL_PRE}/images/gaodeVec/`, // 街道图层
  img: `${MAP_SERVE_URL_PRE}/images/gaodeImg/`, // 卫星图层
  selfbuilding: `${MAP_SERVE_URL_PRE}/two_d/` // 自建图层
  //selfbuilding: `http://10.127.20.98/two_d/` // 自建图层
};

const ORIGIN_MAP_ZOOM = 16; // 默认缩放级别
const MAP_ZOOM_MIN = 4; // 最小缩放级别
const MAP_ZOOM_MAX = 21; // 最大缩放级别
//  苏研所 [120.793897861,31.271434521]  上海广电5G：[121.605683, 31.200508] ，山东烟台：[121.193540716,37.543171008]
// 默认中心点 [120.793849, 31.271427]
const ORIGIN_LONGLAT = [120.793849, 31.271427]; // 默认中心点 [120.793849, 31.271427]
const LOCAL_NET = false; // 是否内网模式
const CIRCLE = {
  radius: 6000, // 默认的机库圆半径(m)
  fill: '#55555559',
  stroke: '#333333',
  border: 2
};
const GAODE = {
  weatherKey: '98b72907fc6d5aee4b3186d64e56e476',
  gaodeWeather: 'https://restapi.amap.com/v3/weather/weatherInfo?',
  gaodeLoc: 'https://restapi.amap.com/v3/geocode/regeo?'
};
const POLYGON = {
  stroke: 2,
  fobidden: {
    type: 'fobidden',
    fill: '#ff000099',
    stroke: 'red'
  },
  interest: {
    type: 'interest',
    fill: 'green',
    stroke: 'green'
  }
};
const TRAIL = {
  color: '#1588d1',
  realColor: '#00ff00',
  realOpacityColor: '#00ff0080',
  width: 6,
  radius: 2,
  fillColor: '#f00',
  minDistance: 1 // 最小的间隔距离 米
};

const ROUTE_NEW = {
  maxIntervalMeter: 1500, // 航点间隔最大值距离（m）
  color: 'yellow',
  dashcolor: 'yellow',
  width: 4,
  radius: 13,
  point: {
    font: '14px Microsoft YaHei', // 字体样式
    fillColor: 'white', // 字体颜色
    bgFillColor: '#037aff',
    bgStrokeColor: 'white',
    border: 2,
    borderColor: 'white',
    fontPadding: [20, 2, 2, 2]
  },
  // 选中的样式
  pointSelected: {
    fillColor: 'white',
    font: '12px Helvetica Neue Helvetica PingFang SC Hiragino Sans GB Microsoft YaHei 微软雅黑 Arial sans-serif ',
    bgFillColor: '#ff7f2d',
    bgStrokeColor: 'white'
  }
};


const ROUTE = {
  maxIntervalMeter: 1500, // 航点间隔最大值距离（m）
  color: '#ffa118',
  dashcolor: '#ffa118c7',
  width: 6,
  radius: 13,
  point: {
    font: '14px Microsoft YaHei', // 字体样式
    fillColorr: '#222', // 字体颜色
    bgFillColor: '#ffeb3b',
    bgStrokeColor: '#e6ce00',
    border: 1,
    borderColor: '#e6ce00',
    fontPadding: [2, 2, 2, 2],
  },
  // 选中的样式
  pointSelected: {
    fillColor: 'white',
    font: '12px Helvetica Neue Helvetica PingFang SC Hiragino Sans GB Microsoft YaHei 微软雅黑 Arial sans-serif ',
    bgFillColor: '#fa5252',
    bgStrokeColor: '#e6ce00',
  },
};

const DRAW_STYLE = {
  fillColor: 'rgba(255, 255, 255, 0.2)',
  strokeWidth: 2,
  strokeColor: '#ffcc33',
  circleRadius: 7,
  circleFillColor: '#ffcc33',


}

const EPSG = 'EPSG:3857'; // 4326,3857

const LASER_DISTANCE_MAX = 12000; // 激光测距最远距离 ，单位：米

const CLICKMODE = {
  mission: 'mission',
  none: 'none',
  mainHive: 'mainHive',
  marker: 'marker', // 打单个点模式
  tempmarker: 'tempmarker', // 临时航点
  sitemarker: 'sitemarker', // 站点
  safemarker: 'safemarker', // 安全点
  landingmarker: 'landingmarker', // 备降点
  route: 'route', // 航线打点模式
  polygon: 'polygon', // 绘制普通多边形模式
  fobidden: 'fobidden', // 绘制禁飞区模式
  dronemarker: 'dronemarker'
};

const CESIUM_PARAM = {
  mark: `https://{s}.tianditu.gov.cn/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk=${TDTTK}`, // 标注
  base: `https://{s}.tianditu.gov.cn/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk=${TDTTK}`, // 底图
  // street: 'https://{s}.tianditu.gov.cn/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk=' + TDTTK,
  // base: 'http://{s}.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0' + '&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' + '&style=default&format=tiles&tk=' + TDTTK,
  // 标注
  markUrl: `https://t5.tianditu.gov.cn/mapservice/GetTiles?tk=${TDTTK}`,
  terreain: `https://t5.tianditu.gov.cn/mapservice/swdx?tk=${TDTTK}`,
  // 三维建筑模型基地址
  BIM_BASE: `https://skyscout.skysys.cn/three_d/`,

  terra_maiteng: `https://skyscout.skysys.cn/three_d/jinqiao3D/terra_b3dms/tileset.json`,
  centerHt: 500,
  track_width: 2.5,
  line_width: 2,
  line_alpha: 1,
  entity_minHt: 10, // 最小10米高度
  back_m: 500, // 后方视角距离
  follow_m: 100, // 后方视角距离
  top_m: 5000, // 顶部距离paraValue.top_m  5000
  back_pitch: -10, // -10 侧方
  top_pitch: -90, // -90 俯仰角
  uav_minscale: 100, // 无人系统缩放级别min
  uav_maxscale: 100000, // 无人系统缩放级别max
  uav0806head_offset: -90, // 0806无人系统航向偏移量`:160    2:-90  2021年3月15日   ？？？？？
  othershead_offset: 85 // 0806无人系统航向偏移量85
};

export {
  CESIUM_PARAM,
  TDTTK,
  EPSG,
  CIRCLE,
  ORIGIN_LONGLAT,
  ORIGIN_MAP_ZOOM,
  MAP_ZOOM_MAX,
  MAP_ZOOM_MIN,
  LAYER_LOCAL,
  LAYER,
  TRAIL,
  POLYGON,
  ROUTE,
  CLICKMODE,
  LOCAL_NET,
  GAODE,
  LASER_DISTANCE_MAX,
  DRAW_STYLE
};
