<template>
  <div id="mapContainer" class="map-container" ref="mapContainer">
    <div class="base-box" :style="rightPos ? 'right:' + rightPos : 'right:10px'">
      <div class="base-wrapper">
        <el-tooltip placement="left" content="工具箱">
          <template slot="title">
            <span>工具箱</span>
          </template>
          <div class="layer common" @click="boxHandler('tool')">
            <img :class="boxCtrl == 'tool' ? 'hilight' : ''" src="@/assets/images/map/tool.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="图层">
          <template slot="title">
            <span>图层</span>
          </template>
          <div class="layer common" @click="boxHandler('layer')">
            <img :class="boxCtrl == 'layer' ? 'hilight' : ''" src="@/assets/images/map/layer.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="定位">
          <template slot="title">
            <span>定位</span>
          </template>
          <div class="location common" @click="initLoaction()">
            <img src="@/assets/images/map/location.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="放大">
          <template slot="title">
            <span>放大</span>
          </template>
          <div class="zoomin common" @click="zoomIn">
            <img src="@/assets/images/map/zoomin.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="缩小">
          <template slot="title">
            <span>缩小</span>
          </template>
          <div class="zoomout common" @click="zoomOut">
            <img src="@/assets/images/map/zoomout.png" />
          </div>
        </el-tooltip>
        <el-tooltip v-if="allow3D" placement="left" content="3D">
          <template slot="title">
            <span>3D</span>
          </template>
          <div class="zoomout common" @click="to3D">
            <img src="@/assets/images/map/3d.png" />
          </div>
        </el-tooltip>
        <div v-show="boxCtrl == 'layer'" class="layer-box">
          <el-collapse v-model="activeName">
            <template v-for="(layer, index) in layers">
              <el-collapse-item :title="layer.name" :key="'layer_' + index" :name="index">
                <template v-for="(layer1, idx) in layer.layers">
                  <template v-if="layer1.collapse">
                     <el-collapse v-model="activeSubName">
                      <template v-for="(layer2, index2) in layer1.layers">
                       <el-collapse-item :title="layer2.name" :key="'layer2_' + index2" :name="index2">

                          <template v-for="(layer3, index3) in layer2.layers">
                            <div class="switch-item">
                              <div>{{ layer3.name }}</div>
                              <div>
                                <el-switch v-model="layer3.show" active-color="#13ce66" @change="changeLayer(layer3)"></el-switch>
                              </div>
                            </div>
                          </template>

                        </el-collapse-item>
                      </template>
                     </el-collapse>
                    
                  </template>
                  <template v-else>
                      <div class="switch-item">
                        <div>{{ layer1.name }}</div>
                        <div>
                          <el-switch v-model="layer1.show" active-color="#13ce66" @change="changeLayer(layer1)"></el-switch>
                        </div>
                      </div>
                  </template>
                </template>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>

        <div v-show="boxCtrl == 'tool'" class="layer-box">
          <div class="base-layer-box">
            <div class="title">选择工具</div>
            <div v-for="item in tools" class="tool-item" :class="calcDraw == item.value ? 'is-drawing' : ''" @click="startGeometryByType(item.value)">
              <img :src="calcDraw == item.value ? item.imgS : item.img" />
              {{ item.name }}
              <div class="spot" v-if="calcDraw == item.value"></div>
            </div>

            <div class="tool-item" @click="clearDrawedGeometries()">
              <img src="@/assets/images/map/tool/clear.png" />
              清除
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="selectIndex >= 0" class="contextmenu_uav">
      <div class="pt-menu">
        <div>路点信息</div>
        <div>经度：{{ currentmarkerLng }}</div>
        <div>纬度：{{ currentmarkerLat }}</div>
        <div>高度：{{ currentmarkerHt }}</div>
        <div>云台俯仰角：{{ currentmarkerGimbalPitch }}</div>
        <div>动作：{{ currentmarkerAction.length === 0 ? '无' : '' }}</div>
        <div v-for="(act, index) in currentmarkerAction" :key="'act_' + index">
          {{ act }}
        </div>
      </div>
    </div>

    <div v-show="selectedHiveMarker" class="contextmenu_hive">
      <div class="pt-menu">
        <div class="title">站点名称：{{ selectedHiveMarker ? selectedHiveMarker.siteName : '' }}</div>
        <div class="img">
          <img :src="siteSelectedHiveImg" />
        </div>

        <div class="two" v-if="(selectedHiveMarker && selectedHiveMarker.siteMode == 1) || (selectedHiveMarker && selectedHiveMarker.siteMode == 2)">
          <div>机库型号：{{ siteSelectedHiveModel }}</div>
        </div>
        <div class="two">
          <div>无人机型号：{{ siteSelectedUavModel }}</div>
        </div>
        <div class="button">
          <el-button type="primary" @click="gotoHanlerCenter">操作中心</el-button>
        </div>
      </div>
    </div>

    <div v-show="mapClickMode === CLICKMODE.tempmarker" id="mousemovetitle">左键单击选择航点</div>

    <div id="drawingLengthText">{{ drawingLengthText }}</div>
  </div>
</template>

<script>
import 'ol/ol.css'; // 样式
import OLMap from 'ol/Map'; // 地图
import View from 'ol/View'; // 视图
import { ScaleLine,defaults as defaultCtronls } from 'ol/control'; // 视图
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { fromLonLat, toLonLat } from 'ol/proj'; // 坐标转换
import { getDistance, getArea, getLength } from 'ol/sphere';
import { Style, Icon, Fill, Stroke, Text, Circle } from 'ol/style';
import { Circle as CircleGeom, Polygon, Point, LineString } from 'ol/geom';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import { unByKey } from 'ol/Observable';
import { Modify, Draw, Select, Snap, defaults } from 'ol/interaction';
import { XYZ, Vector, WMTS } from 'ol/source'; // 地图源
// eslint-disable-next-line max-len
import { ORIGIN_MAP_ZOOM, LAYER,MAP_ZOOM_MIN, MAP_ZOOM_MAX, ORIGIN_LONGLAT, EPSG, TRAIL, ROUTE, LAYER_LOCAL, CLICKMODE, CIRCLE, POLYGON, LOCAL_NET, DRAW_STYLE } from './map.config.js';
import coordtransform from '@/utils/coordtransform.js'; // 坐标转换
import mapMixin from './mapMixin';

const point = require('@/assets/images/map/tool/point.png');
const point_grey = require('@/assets/images/map/tool/point_grey.png');
const circle = require('@/assets/images/map/tool/circle.png');
const circle_grey = require('@/assets/images/map/tool/circle_grey.png');
const line = require('@/assets/images/map/tool/line.png');
const line_grey = require('@/assets/images/map/tool/line_grey.png');
const polygon = require('@/assets/images/map/tool/polygon.png');
const polygon_grey = require('@/assets/images/map/tool/polygon_grey.png');
const laserTmpId = {
  lngLat: 'laser_lnglat',
  spdHt: 'laser_spd_pos'
};
const overLayIdPre = '_overLay';

const missionmarkerIdPre = 'mission-marker-';
const actionMap = {
  1: '悬停：',
  2: '开始录像',
  3: '停止录像',
  4: '云台俯仰角：',
  5: '拍照',
  6: '偏航角：',
  7: '变焦倍数：',
  8: '云台绝对航偏角：',
  9: '云台相对航偏角：',
  10: '间隔拍照：',
  11: '间隔拍照：',
  12: '停止间隔拍照'
};
const actionUnitMap = {
   1: 's',
  2: '',
  3: '',
  4: '°',
  5: '',
  6: '°',
  7: '倍',
  8: '°',
  9: '°',
  10: 's',
  11: 'm',
  12: ''
};

const LAYERS = {
  base: 'base',
  routeNet: 'routeNet',
  siteLite: 'siteLite',
  base_TDT: 'base_TDT',
  routeNet_TDT: 'routeNet_TDT',
  siteLite_TDT: 'siteLite_TDT',

  selfBuiding: 'selfBuiding',
  forbidden: 'forbidden',
  limit: 'limit',
  auth: 'auth',
  work: 'work'
};

let missionPathMarkers = [];
const DELIMG = require('@/assets/images/map/tool/del.png');
export default {
  name: 'OlMap',
  mixins: [mapMixin],
  props: {
    zoom: {
      type: Number,
      default() {
        return ORIGIN_MAP_ZOOM;
      }
    },
    rightPos: {
      type: String,
      default() {
        return '';
      }
    },
    allow3D: {
      type: Boolean,
      default() {
        return true;
      }
    },
    maxZoom: {
      type: Number,
      default() {
        return MAP_ZOOM_MAX;
      }
    },
    center: {
      type: Array,
      default() {
        return ORIGIN_LONGLAT;
      }
    },
    locationEditing: {
      type: Object,
      default() {
        return {
          id: null,
          longitude: 0,
          latitude: 0
        };
      }
    },
    site: {
      type: Object,
      default() {
        return {};
      }
    },
    drawAble: {
      type: Boolean,
      default() {
        return true;
      }
    },
    page: null,
    siteSelected: null
  },
  data() {
    return {
      toolImg: {
        point,
        point_grey,
        circle,
        circle_grey,
        line,
        line_grey,
        polygon,
        polygon_grey
      },

      tools: [
        {
          name: '单点',
          value: 'Point',
          img: point_grey,
          imgS: point
        },
        {
          name: '折线',
          value: 'LineString',
          img: line_grey,
          imgS: line
        },
        {
          name: '多边形',
          value: 'Polygon',
          img: polygon_grey,
          imgS: polygon
        },
        {
          name: '圆形',
          value: 'Circle',
          img: circle_grey,
          imgS: circle
        }
      ],
      layers: {
        twoD: {
          name: '2D地图',
          layers: {
            types:{
              collapse:true,
              layers:{
              gd:{
               name: '高德地图',
              layers:{
                 base: {
                  name: '高德底图',
                  value: LAYERS.base,
                  show: true
                },
                routeNet: {
                  name: '高德路网',
                  value: LAYERS.routeNet,
                  show: false
                },
                siteLite: {
                  name: '高德卫星',
                  value: LAYERS.siteLite,
                  show: false
                },
              }
             },
            //   tdt:{
            //    name: '天地图',
            //   layers:{
            //     base_TDT: {
            //       name: '天地图底图',
            //       value: LAYERS.base_TDT,
            //       show: false
            //     },
            //     routeNet_TDT: {
            //       name: '天地图路网',
            //       value: LAYERS.routeNet_TDT,
            //       show: false
            //     },
            //     siteLite_TDT: {
            //       name: '天地图卫星',
            //       value: LAYERS.siteLite_TDT,
            //       show: false
            //     },
            //   }
            //  },
            },
            },
            
             
            
           
            selfBuiding: {
              name: '自建图层',
              value: LAYERS.selfBuiding,
              show: true
            }
          }
        },
        
        //  limit: {
        //     name: '飞行限制区',
        //     layers: {
        //       forbidden:{
        //         name: '禁飞区',
        //         value: LAYERS.forbidden,
        //         show: false
        //       },
        //       limit: {
        //         name: '限飞区',
        //         value: LAYERS.limit,
        //         show: false
        //       },
        //       auth: {
        //         name: '授权空域',
        //         value: LAYERS.auth,
        //         show: false
        //       },
        //       work: {
        //         name: '作业区',
        //         value: LAYERS.work,
        //         show: false
        //       }
        //     }
        //   }
      },

      activeName: ['twoD'],
      activeSubName:[],
      switches: {
        limitedLayer: false
      },
      boxCtrl: '',
      map: null,
      laserPoint: null, // 激光测距目标点
      mousemovetitle: null,
      selectedHiveMarker: null,
      myZoom: ORIGIN_MAP_ZOOM,
      contextmenu_uav: null,
      contextmenu_hive: null,
      selectIndex: -1, // 任务航线选中的航点下标，弹窗显示信息
      nextMarkerPreIndex: -1, // 打点规划航点时，插入航点的前下标
      lngLat_ol_tmp: null,
      streetMode: true,
      routeNetMode: false,
      selfMode: false,
      CLICKMODE,
      F_Feature: null,
      drawInteraction: null,
      uavTrail: null,
      realTimeTrail: null, // 实时轨迹
      mapClickMode: null, // 点击地图处理模式
      missionPath: null,
      polygon: null,
      realTimeTrails: [],
      missionPathMarkers: [], // 航线航点或多边形数组
      missionModify: null, // 航点的交互
      draw: null,
      hiveMarker: null,
      endHiveMarker: null,
      hiveMarkerMap: new Map(),
      hiveMarkerCircle: null,
      uavMarkerMap: new Map(),
      gimbalMarkerMap: new Map(),
      view: null,
      markLayer: null, // 标注图层
      baseLayer: null,
      routeNetLayer: null, // 路网图层
      satelliteLayer: null, // 卫星图层

      baseLayer_TDT: null, //天地图底图
      routeNetLayer_TDT: null, // 天地图路网图层
      satelliteLayer_TDT: null, // 天地图卫星图层

      privateLayer: null, // 自建图层
      mapVector: null, //、
      layerVector: null, // 自定义禁飞区
      layerModify: null, // 图层交互
      layerDraw: null,
      layerSnap: null,
      hiveAndPathVector: null, // 机库图层,航线折线

      trailVector: null, // 机库图层
      trailRealTimeVector: null,
      //  ,routeVector:null //
      calcAvalible: false,
      drawingLengthText: '',
      drawingLengthTextOverlay: null,
      drawingPolygonAreaTextOverlay: null,
      drawingCicleAreaTextOverlay: null,
      sketchListener: null,
      drawSketch: null,
      calcDraw: null,
      calcModify: null,
      calcSelect: null,
      calcSnap: null,
      drawOverlayIds: [],
      drawCoordTemp: [],

      draws: {
        Point: null,
        LineString: null,
        Polygon: null,
        Circle: null
      }
    };
  },
  computed: {
    siteSelectedHiveImg() {
      return this.selectedHiveMarker ? this.selectedHiveMarker.pic : '';
    },
    siteSelectedHiveModel() {
      if (this.selectedHiveMarker?.hiveinfo?.hiveModel) {
        return this.selectedHiveMarker.hiveinfo.hiveModel;
      }
      return '未知';
    },

    siteSelectedUavModel() {
      if (this.selectedHiveMarker?.UAVInfo?.model) {
        return this.selectedHiveMarker.UAVInfo.model;
      }
      return '未知';
    },

    streetModeImg() {
      if (!this.streetMode) {
        return this.layerCtrl.streetImg;
      }
      return this.layerCtrl.satelliteImg;
    },
    currentmarkerLng() {
      if (this.selectIndex >= 0 && missionPathMarkers[this.selectIndex]) {
        return `${parseFloat(missionPathMarkers[this.selectIndex].longitude.toFixed(7))}°`;
      }
      return '未知';
    },
    currentmarkerLat() {
      if (this.selectIndex >= 0 && missionPathMarkers[this.selectIndex]) {
        return `${parseFloat(missionPathMarkers[this.selectIndex].latitude.toFixed(7))}°`;
      }
      return '未知';
    },
    currentmarkerHt() {
      if (this.selectIndex >= 0 && missionPathMarkers[this.selectIndex] && missionPathMarkers[this.selectIndex].altitude) {
        return `${parseFloat(missionPathMarkers[this.selectIndex].altitude.toFixed(2))}m`;
      }
      return '未知';
    },
    currentmarkerGimbalPitch() {
      let gimbalPitch = 0;
      if (this.selectIndex >= 0 && missionPathMarkers[this.selectIndex] && missionPathMarkers[this.selectIndex].gimbalPitch) {
        gimbalPitch = missionPathMarkers[this.selectIndex].gimbalPitch;
      }
      return `${gimbalPitch}°`;
    },

    currentmarkerAction() {
      const arr = [];
      if (this.selectIndex >= 0 && missionPathMarkers[this.selectIndex] && missionPathMarkers[this.selectIndex].actions && missionPathMarkers[this.selectIndex].actions.length > 0) {
        const { actions } = missionPathMarkers[this.selectIndex];
        // eslint-disable-next-line no-restricted-syntax
        for (const act of actions) {
          if (actionUnitMap[act.action]) {
            arr.push(actionMap[act.action] + act.actionParams + actionUnitMap[act.action]);
          } else {
            arr.push(actionMap[act.action]);
          }
        }
      }
      return arr;
    }
  },

  watch: {
    locationEditing(n) {
      const obj = {
        longitude: n.longitude,
        latitude: n.latitude
      };
      this.addMarkerByClickMode(obj, n.id);
    },

    // eslint-disable-next-line func-names
    '$store.state.app.sidebar.opened': function () {
      this.updateSize();
    },
    site(n) {
      if (n.siteID && !this.privateLayer) {
        this.privateLayer = new TileLayer({
          title: '自建图层',
          visible: true,
          zIndex: 100,
          map: this.map,
          // 内网自建图层
          source: new XYZ({
            tileUrlFunction(tileCoord) {
              const z = tileCoord[0];
              const x = tileCoord[1];
              const y = tileCoord[2];
              return `${LAYER_LOCAL.selfbuilding}${n.siteID}/${z}/${x}/${y}.png`;
            }
          })
        });
      }
    }
  },

  mounted() {
    this.createMap();
    if (this.page == 'main') {
      this.layers.twoD.layers.types.layers.gd.layers.siteLite.show = true;
      this.layers.twoD.layers.selfBuiding.show = false;
      this.streetModeChange();
    }
  },

  beforeDestroy() {
    this.map.getLayers().forEach(layer => {
      this.map.removeLayer(layer);
    });
    this.map.getInteractions().forEach(interation => {
      this.map.removeInteraction(interation);
    });
    this.map.getOverlays().forEach(overlay => {
      this.map.removeOverlay(overlay);
    });
    this.map.getControls().forEach(control => {
      this.map.removeControl(control);
    });
    this.removeMissionPath();
    this.map = null;
  },
  methods: {
    to3D() {
      this.$emit('threeD');
    },
    // 定位
    initLoaction() {
      if (this.hiveMarker) {
        this.panTo(...this.hiveMarker.lngLat);
      } else {
        this.panTo(...this.center);
      }
    },

    changeLayer(layer) {
      let visible = layer.show;
      let layerName = layer.value;
      if (layerName) {
        if (layerName == LAYERS.base) {
          this.baseLayer.setVisible(visible)
          this.markLayerSource.setVisible(visible)
        }else if (layerName == LAYERS.base_TDT) {
          this.baseLayer_TDT.setVisible(visible)
        }else if (layerName == LAYERS.routeNet) {
          this.routeNetLayer.setVisible(visible);
        }else if (layerName == LAYERS.routeNet_TDT) {
         this.routeNetLayer_TDT.setVisible(visible);
        } else if (layerName == LAYERS.siteLite) {
          this.satelliteLayer.setVisible(visible)
        }else if (layerName == LAYERS.siteLite_TDT) {
          this.satelliteLayer_TDT.setVisible(visible);
        } else if (layerName == LAYERS.selfBuiding) {
          this.setPrivateLayerVisible(visible);
        }
      }
    },
    // 初始化地图
    createMap() {
      this.view = new View({
        projection: EPSG,
        center: fromLonLat(this.center, EPSG),
        constrainResolution: true,
        showFullExtent: true,
        resolution: 1,
        smoothExtentConstraint: false,
        zoom: this.zoom || ORIGIN_MAP_ZOOM, // 缩放级别
        minZoom: MAP_ZOOM_MIN, // 最小缩放级别
        maxZoom: this.maxZoom
      });

      let markLayerSource = new WMTS({ url: LAYER.SourceMarkUrl});
      this.markLayer = new TileLayer({
        title: '标注图层',
        visible: false,
        source: markLayerSource,
        zIndex: 6
      }); 

      let baseLayerSource = new XYZ({ url: LAYER.GD_JD });
      this.baseLayer = new TileLayer({
        title: '高德街道图层',
        visible: true,
        // 公网网地址 source
        source: baseLayerSource
      });

      let satelliteLayerSource = new XYZ({ url: LAYER.GD_WX });
      this.satelliteLayer = new TileLayer({
        title: '高德卫星图层',
        visible:this.layers.twoD.layers.types.layers.gd.layers.siteLite.show,
        // 公网卫星
        source: satelliteLayerSource
      });
      let routeNetLayerSource = new XYZ({ url: LAYER.GD_LW });
      this.routeNetLayer = new TileLayer({
        title: '高德路网图层',
        visible: false,
        // 公网卫星
        source: routeNetLayerSource
      });



      // 天地图
        let baseLayerSource_TDT = new XYZ({ url: LAYER.TDT_JD });
      this.baseLayer_TDT = new TileLayer({
        title: '天地图街道图层',
        visible: false,
        source: baseLayerSource_TDT
      });

      let satelliteLayerSource_TDT = new XYZ({ url: LAYER.TDT_WX });
    
      this.satelliteLayer_TDT = new TileLayer({
        title: '天地图卫星图层',
        visible: false,
        // 公网卫星
        source: satelliteLayerSource_TDT
      });

      let routeNetLayerSource_TDT = new XYZ({ url: LAYER.TDT_LW });
     
      this.routeNetLayer_TDT = new TileLayer({
        title: '天地图路网图层',
        visible: false,
        // 公网卫星
        source: routeNetLayerSource_TDT
      });











      let layers = [this.baseLayer, this.markLayer,this.baseLayer_TDT, this.satelliteLayer,this.satelliteLayer_TDT, this.routeNetLayer, this.routeNetLayer_TDT];
      let that = this;
      if (this?.site?.siteID) {
        this.privateLayer = new TileLayer({
          title: '自建图层',
          visible: true,
          zIndex: 100,
          // 内网自建图层
          source: new XYZ({
            tileUrlFunction(tileCoord) {
              const z = tileCoord[0];
              const x = tileCoord[1];
              const y = tileCoord[2];
              return `${LAYER_LOCAL.selfbuilding}${that.site.siteID}/${z}/${x}/${y}.png`;
            }
          })
        });
        layers.push(this.privateLayer);
      }
      this.map = new OLMap({
        target: 'mapContainer',
        interactions: defaults({ doubleClickZoom: false }),
        layers,
        controls: defaultCtronls({
          zoom: false
        }),
        view: this.view
      });
      const scaleLineControl = new ScaleLine({
        // 设置度量单位为米
        units: 'metric',
        target: 'scalebar',
        className: 'ol-scale-line'
      });
      this.mousemovetitle = new Overlay({
        element: document.getElementById('mousemovetitle'),
        offset: [5, 8],
        positioning: 'top-left',
        stopEvent: false,
        className: 'first-label'
      });
      this.map.addControl(scaleLineControl);
      this.map.addOverlay(this.mousemovetitle);
      this.initmapVector();
      const _this = this;

      this.map.on('moveend', () => {
        if (_this.map) {
          _this.myZoom = parseFloat(_this.map.getView().getZoom().toFixed(2));
        }
      });

      this.map.on('pointermove', evt => {
        if (_this.mapClickMode === CLICKMODE.tempmarker) {
          _this.mousemovetitle.setPosition(evt.coordinate);
        }
      });
      // 地图加载完成
      this.map.once('postrender', () => {
        _this.$emit('postrender');
        // 地图绑定鼠标右击事件——弹出右键菜单
        //    _this.map.on('singleclick', _this.singleClickMap);
        // 地图点击事件
        _this.map.on('click', event => {
          console.log('map click');
          const { pixel } = event;
          _this.stopMarkersHighLight();
          if (_this.page === 'main') {
            setTimeout(() => {
              _this.selectedHiveMarker = null;
            }, 100);
          }
          // 航线打点模式取消中间取点
          let allowClick = true;
          if (_this.map.hasFeatureAtPixel(pixel)) {
            _this.map.forEachFeatureAtPixel(pixel, feature => {
              if (feature.type === _this.CLICKMODE.mission) {
                // 点击了任务航线航点
                _this.selectIndex = feature.index;
                _this.setmarkerHighlight(missionPathMarkers[feature.index]);
                _this.showInfoWindow(feature);
              } else if (feature.type === _this.CLICKMODE.route) {
                // 航线上的点 点击高亮
                _this.setmarkerHighlight(feature);
                _this.$emit('selected', feature.index);
                if (feature.index < missionPathMarkers.length) {
                  // 最后一个点点击直接算作最后插入的点
                  _this.nextMarkerPreIndex = feature.index;
                }
                allowClick = false;
              } else if (feature.type === _this.CLICKMODE.mainHive) {
                setTimeout(() => {
                  _this.showHiveWindow(feature);
                }, 200);
              } // 预留
            });
            if (allowClick && _this.mapClickMode) {
              _this.addMarkerByClickMode(event);
            }
          } else {
            // 捕捉点击事件
            // eslint-disable-next-line no-lonely-if
            if (_this.mapClickMode && _this.mapClickMode !== CLICKMODE.none) {
              _this.addMarkerByClickMode(event);
            }
          }
        });
      });
    },

    boxHandler(val) {
      if (this.boxCtrl === '') {
        this.boxCtrl = val;
      } else {
        if (this.boxCtrl == val) {
          this.boxCtrl = '';
        } else {
          this.boxCtrl = val;
          if (val === 'layer') {
            this.$nextTick(() => {
              this.activeName = 0;
            });
          }
        }
      }
    },

    zoomIn() {
      this.map.getView().setZoom(this.map.getView().getZoom() + 1);
    },
    zoomOut() {
      this.map.getView().setZoom(this.map.getView().getZoom() - 1);
    },
    updateSize() {
      const _this = this;
      setTimeout(() => {
        _this.map.updateSize();
      }, 300);
    },

    // 根据单位m换算投影单位
    getRadius(radius) {
      const metersPerUnit = this.map.getView().getProjection().getMetersPerUnit();
      const circleRadius = radius / metersPerUnit;
      return circleRadius;
    },

    // 根据投影单位换算单位m
    fromRadius(circleRadius) {
      const metersPerUnit = this.map.getView().getProjection().getMetersPerUnit();
      const radius = circleRadius * metersPerUnit;
      return radius;
    },

    getImgById(mode) {
      switch (mode) {
        case CLICKMODE.sitemarker:
          return this.ptImg.sitemarker;
        case CLICKMODE.safemarker:
          return this.ptImg.safemarker;
        case CLICKMODE.landingmarker:
          return this.ptImg.landingmarker;
        case CLICKMODE.tempmarker:
          return this.ptImg.tempmarker;
        default:
          return null;
      }
    },
    // 卫星和街道图层的切换
    streetModeChange() {
      this.streetMode = !this.streetMode;
      this.setSatelliteLayerVisible(!this.streetMode);
    },
    // 路网图层
    routeNetModeChange() {
      this.routeNetMode = !this.routeNetMode;
      this.setRouteNetLayerVisible(this.routeNetMode);
    },

    initModifyInteraction() {
      this.removeModifyInteraction();
      this.missionModify = new Modify({
        source: this.mapVector.getSource()
        // pixelTolerance: 5
      });
      this.map.addInteraction(this.missionModify);
      this.missionModify.on('modifystart', event => {
        const m = event.features.getArray()[0];
        if (m && m.lngLat_ol) {
          this.lngLat_ol_tmp = m.lngLat_ol;
        } else {
          this.lngLat_ol_tmp = null;
        }
      });
      this.missionModify.on('modifyend', event => {
        //  let m = event.features.getArray()[0]
        const arr = event.features.getArray();
        if (arr && arr.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const m of arr) {
            const ollngLat = toLonLat(m.getGeometry().getCoordinates(), EPSG);
            const gpslngLatTmp = coordtransform.gcj02towgs84(ollngLat[0], ollngLat[1]);
            const gpslngLat = [parseFloat(gpslngLatTmp[0].toFixed(7)), parseFloat(gpslngLatTmp[1].toFixed(7))];
            m.lngLat = gpslngLat;
            m.lngLat_ol = ollngLat;
            if (this.mapClickMode === CLICKMODE.route) {
              const distanceAllow = this.checkDistanceByIndex(m.index, this);
              let circleAllow = false;
              if (this.hiveMarkerCircle) {
                if (this.hiveMarkerCircle.getGeometry().intersectsCoordinate(m.getGeometry().getCoordinates())) {
                  circleAllow = true;
                }
              } else {
                circleAllow = true;
              }
              if (!circleAllow) {
                this.$emit('errorMsg', '超出机库区域范围！');
              }
              if (distanceAllow && circleAllow) {
                this.resetMissionPathByMarkers();
              } else {
                this.recoverMarker(m, this);
                return;
              }
              this.stopMarkersHighLight();
              this.setmarkerHighlight(m);
              this.$emit('selected', m.index);
            } else if (this.mapClickMode === CLICKMODE.polygon) {
              // 绘制多边形模式
              this.resetPolygonByMarkers();
            }
            const obj = {
              index: m.index,
              id: m.getId(),
              longitude: gpslngLat[0],
              latitude: gpslngLat[1],
              distance: this.getMissionPathTotalLength()
            };

            this.$emit('changePostion', obj);
          }
        }
      });
    },

    // 获取自定义图层的样式
    getSketchStyleByLayerType(layerType) {
      let fillColor = null,
        strokeColor = '',
        width=2;
      //类别 1-禁飞区 2-限飞区  3-授权空域   4-作业区
      if (layerType == 1) {
        // 红色
        fillColor= 'rgba(255,0,0,0.5)';
         strokeColor = 'rgba(255,0,0,0.8)';
      } else if (layerType == 2) {
        // 黄色
        fillColor= 'rgba(255,255,0,0.5)';
         strokeColor = 'rgba(255,255,0,0.8)';
      } else if (layerType == 3) {
        // 绿色
         fillColor= 'rgba(0,128,0,0.4)';
         strokeColor = 'rgba(0,128,0,0.8)';
      } else if (layerType == 4) {
        // 蓝色
         fillColor= 'rgba(0,0,255,0.4)';
         strokeColor = 'rgba(0,0,255,0.8)';
      }else{
         fillColor= 'rgba(54,198,251,0.2)';
         strokeColor = 'rgba(54,198,251,1)';
         width=3
      }

      return new Style({
        fill: new Fill({
          color:fillColor
        }),
        stroke: new Stroke({
          color: strokeColor,
          width
        })
      });
    },
    initSelfLayerGeometries(geometries){
      geometries.forEach(geom=>{
        
        let feature=null
        if(geom.type===1){ // 圆
         let olLngLat = coordtransform.wgs84togcj02( geom.coordinate[0], geom.coordinate[1])
          feature= new CircleGeom(fromLonLat(olLngLat), geom.radius)
        }else if(geom.type===2){ // 多边形
         let coordinates = []
         geom.coordinates.forEach(coor=>{
          console.log(coor)
           coordinates.push(fromLonLat(coordtransform.wgs84togcj02(coor[0], coor[1]), EPSG)) 
         })
         console.error(coordinates)
          feature= new Feature(new Polygon([coordinates]));
        }
        feature.setId(new Date().getTime() + '');
        this.layerVector.getSource().addFeature(feature)
        this.addSelfLayerDelFeatureBtn(feature);
      })

    },
    // 禁飞区图层交互
    initLayerInteraction(start = true, type, layerType) {
      let that = this;
      if (this.layerModify) {
        this.map.removeInteraction(this.layerModify);
      }
      if (this.layerDraw) {
        this.map.removeInteraction(this.layerDraw);
      }
      if (this.layerSnap) {
        this.map.removeInteraction(this.layerSnap);
      }
      this.layerSnap = null;
      this.layerDraw = null;
      this.layerModify = null;
      let style = this.getSketchStyleByLayerType(layerType);
      if (start) {
        this.layerModify = new Modify({
          source: this.layerVector.getSource(),
        //  style
        });
        this.layerVector.setStyle(style)
        this.layerModify.on('modifyend', function (evt) {
          let features = evt.target.featuresBeingModified_.getArray();
          features.forEach(feature => that.addSelfLayerDelFeatureBtn(feature));
        });
        this.map.addInteraction(this.layerModify);
        this.layerDraw = new Draw({
          source: this.layerVector.getSource(),
          type: type,
          style
        });
        this.layerDraw.on('drawend', function (evt) {
          let feature = evt.feature;
          feature.setId(new Date().getTime() + '');
          // 添加删除按钮
          that.addSelfLayerDelFeatureBtn(feature);
          // 停止绘制
          that.layerDraw.setActive(false);
        });
        this.map.addInteraction(this.layerDraw);
        this.layerSnap = new Snap({ source: this.layerVector.getSource() });
        this.map.addInteraction(this.layerSnap);
      }
    },

    getSelfLayerGeomotries() {
      let features = this.layerVector.getSource().getFeatures();
      let geometries = [];
      for (let feature of features) {
        let geom = null;
        let featureGeom= feature.getGeometry()
        if (featureGeom instanceof CircleGeom) {
          let coor = coordtransform.gcj02towgs84(...toLonLat(featureGeom.getCenter(), EPSG));
          geom = {
            type: 1,
            center: coor,
            radius: featureGeom.getRadius()
          };
        } else if (featureGeom instanceof Polygon) {
          let polygonParams = [];
          let coors = featureGeom.getCoordinates();
          for (let coor of coors) {
            polygonParams.push(coordtransform.gcj02towgs84(...toLonLat(coor, EPSG)));
          }
          geom = {
            type: 2,
            polygonParams
          };
        }
        if (geom) {
          geometries.push(geom);
        }
      }
      return geometries;
    },

    // 自定义图层 各个几何体添加删除按钮
    addSelfLayerDelFeatureBtn(feature) {
      const that = this;
      let geom = feature.getGeometry();
      let id = feature.getId();
      let position = null;
      if (geom instanceof CircleGeom) {
        position = geom.getCenter();
      } else if (geom instanceof Polygon) {
        position = geom.getInteriorPoint().getCoordinates();
      }
      if (position) {
        let overLay = this.map.getOverlayById(id);
        if (overLay) {
          overLay.setPosition(position);
          return false;
        }
        let overlayId = id;
        let element = document.createElement('img');
        element.src = DELIMG;
        element.onclick = function () {
          that.layerVector.getSource().removeFeature(that.layerVector.getSource().getFeatureById(id));
          that.map.removeOverlay(that.map.getOverlayById(overlayId));
        };
        this.$refs.mapContainer.appendChild(element);

        let overlay = new Overlay({
          id: overlayId,
          position,
          element,
          offset: [0, 20],
          positioning: 'center-center',
          stopEvent: false,
          className: 'del-label'
        });
        this.map.addOverlay(overlay);
      }
    },

    // 发送绘制好的几何图形
    getAllGeometriesInLayerVector(feature) {
      if (!feature) {
        return;
      }
      let geometry = null;
      let geom = feature.getGeometry();
      if (geom instanceof Polygon) {
        let coordinates = geom.getCoordinates();
        console.log(coordinates);
        let pts = coordinates.map(coor => {
          let olLngLat = toLonLat(coor, EPSG);
          const [longitude, latitude] = coordtransform.gcj02towgs84(olLngLat[0], olLngLat[1]);
          return {
            longitude,
            latitude
          };
        });
        geometry = {
          type: 'polygon',
          pts
        };
      } else if (geom instanceof Circle) {
        let center = geom.getCenter();
        const [longitude, latitude] = coordtransform.gcj02towgs84(center[0], center[1]);
        let radius = geom.getRadius();
        geometry = {
          type: 'circle',
          radius,
          center: {
            longitude,
            latitude
          }
        };
      }
      this.$emit('geometry', geometry);
    },

    // 移除自定义图层的几何图图形
    removeSelfLayerFeature() {
      this.layerVector.getSource().forEachFeature(feature => {
        this.layerVector.getSource().removeFeature(feature);
        this.map.removeOverlay(this.map.getOverlayById(feature.getId()));
      });
    },

    removeModifyInteraction() {
      if (this.missionModify) {
        this.map.removeInteraction(this.missionModify);
      }
      this.missionModify = null;
    },

    addFeaturesToModifyInteraction() {
      this.missionModify.getFeatures();
    },

    // 单击地图事件处理，打点绘制单个点或折线或多边形
    // 携带id 为其他方法中调用，非地图事件
    // 航线打点模式，航点间距不得大于ROUTE.maxIntervalMeter
    addMarkerByClickMode(event, id) {
      let textSet = false;
      let index = -1;
      let olLngLat;
      if (id) {
        olLngLat = coordtransform.wgs84togcj02(event.longitude, event.latitude);
        const coordinate = fromLonLat(olLngLat, EPSG);
        textSet = true;
        // eslint-disable-next-line no-param-reassign
        event = {
          coordinate
        };
      } else if (this.mapClickMode === CLICKMODE.route || this.mapClickMode === CLICKMODE.polygon) {
        // 绘制航线模式 | 绘制多边形模式
        index = missionPathMarkers.length;
        // eslint-disable-next-line no-param-reassign
        id = missionmarkerIdPre + index;
      } else {
        // eslint-disable-next-line no-param-reassign
        id = this.mapClickMode;
      }
      if (!id) return;
      let m = this.mapVector.getSource().getFeatureById(id);
      if (m) {
        m.getGeometry().setCoordinates(event.coordinate);
      } else {
        const p = new Point(event.coordinate);
        m = new Feature(p);
        m.setId(id);
        m.index = index;
        m.type = this.mapClickMode;
        olLngLat = toLonLat(event.coordinate, EPSG);
        let style;
        if (this.mapClickMode === CLICKMODE.route) {
          // 任务航线打点模式
          // 不允许距离过大 ,此时不存在此点
          const distanceAllow = this.checkDistanceWithLast(olLngLat, this);
          if (!distanceAllow) return false;
          style = this.getMissionPathMarkerStyle(index);
          // 判断是否在圆外
          if (this.hiveMarkerCircle && !this.hiveMarkerCircle.getGeometry().intersectsCoordinate(event.coordinate)) {
            this.$emit('errorMsg', '超出机库区域范围！');
            return false;
          }
        } else if (this.mapClickMode === CLICKMODE.polygon) {
          // 绘制多边形模式 todo
          // todo ???
        } else {
          // 单个点
          style = new Style({
            image: new Icon({
              scale: 0.7,
              anchor: [0.5, 0.5],
              src: this.getImgById(id)
            })
          });
        }
        m.setStyle(style);
        m.lngLat_ol = olLngLat;
        this.mapVector.getSource().addFeature(m);
        if (this.mapClickMode === CLICKMODE.route) {
          if (this.nextMarkerPreIndex >= 0 && this.nextMarkerPreIndex !== missionPathMarkers.length - 1) {
            // 非最后的点
            m.index = this.nextMarkerPreIndex + 1;
            m.setStyle(this.getMissionPathMarkerStyle(m.index));
            missionPathMarkers.splice(this.nextMarkerPreIndex + 1, 0, m);
          } else {
            missionPathMarkers.push(m);
          }
          this.resetMissionPathByMarkers();
        } else if (this.mapClickMode === CLICKMODE.polygon) {
          // 绘制多边形模式
          m.setStyle(this.getMissionPathMarkerStyle(m.index));
          missionPathMarkers.push(m);
          this.resetPolygonByMarkers();
        }
      }
      const gpslngLatOl = toLonLat(event.coordinate, EPSG);
      const gpslngLatTmp = coordtransform.gcj02towgs84(gpslngLatOl[0], gpslngLatOl[1]);
      const gpslngLat = [parseFloat(gpslngLatTmp[0].toFixed(7)), parseFloat(gpslngLatTmp[1].toFixed(7))];
      if (textSet) {
        // 文本输入修改位置
        this.panTo(gpslngLatOl[0], gpslngLatOl[1]);
      } else {
        // 单击地图打点模式（特殊点或者航线）
        const middlePt = this.nextMarkerPreIndex > -1;
        let indexTmp = m.index;
        if (middlePt) {
          indexTmp = this.nextMarkerPreIndex;
        }
        const obj = {
          middlePt,
          index: indexTmp,
          id: m.getId(),
          longitude: parseFloat(gpslngLat[0].toFixed(7)),
          latitude: parseFloat(gpslngLat[1].toFixed(7)),
          distance: this.getMissionPathTotalLength(this)
        };
        this.$emit('changePostion', obj);
      }
      if (this.mapClickMode === CLICKMODE.route) {
        this.nextMarkerPreIndex = -1;
      }
    },

    // 根据航点恢复被拖拽过的点
    recoverMarker(m) {
      // eslint-disable-next-line no-param-reassign
      m.lngLat_ol = this.lngLat_ol_tmp;
      m.getGeometry().setCoordinates(fromLonLat(this.lngLat_ol_tmp, EPSG));
    },

    // 通过下标判断距离是否符合
    checkDistanceByIndex(index, _this) {
      let errorMsg = '航点与上一个航点距离过大！';
      // 与前一个点比较（第一个点和机库比较）
      const preIndex = index - 1;
      if (preIndex >= 0 && preIndex < missionPathMarkers.length) {
        const arr1 = missionPathMarkers[preIndex].lngLat_ol;
        const arr2 = missionPathMarkers[index].lngLat_ol;
        if (!_this.isDistanceMatch(arr1, arr2)) {
          _this.$emit('errorMsg', errorMsg);
          return false;
        }
      } else {
        // 第一个点和机库比较
        errorMsg = '航点与机库距离过大！';
        if (_this.hiveMarker) {
          const arr1 = missionPathMarkers[index].lngLat_ol;
          const arr2 = _this.hiveMarker.lngLat_ol;
          if (!_this.isDistanceMatch(arr1, arr2)) {
            _this.$emit('errorMsg', errorMsg);
            return false;
          }
        }
      }
      // 与后一个点比较
      const nextIndex = index + 1;
      if (nextIndex < missionPathMarkers.length) {
        const arr3 = missionPathMarkers[index].lngLat_ol;
        const arr4 = missionPathMarkers[nextIndex].lngLat_ol;
        if (!_this.isDistanceMatch(arr3, arr4)) {
          _this.$emit('errorMsg', errorMsg);
          return false;
        }
      }
      return true;
    },

    // 判断航线航点是否满足
    isRouteDistanceMatch(siteLocation, pts) {
      const firstLoc = [pts[0].longitude, pts[0].latitude];
      let valid = this.isDistanceMatch(siteLocation, firstLoc);
      if (!valid) {
        return '起点距离站点较远！';
      }
      if (this.hiveMarkerCircle&&!this.hiveMarkerCircle.getGeometry().intersectsCoordinate(fromLonLat(firstLoc, EPSG))) {
        valid = '起点不在站点有效半径内！';
      }
      for (let i = 1; i < pts.length; i += 1) {
        const pre = [pts[i - 1].longitude, pts[i - 1].latitude];
        const next = [pts[i].longitude, pts[i].latitude];
        valid = this.isDistanceMatch(pre, next);
        if(!valid){
          return '存在航点之间距离过大的情况！';
        }
        if (this.hiveMarkerCircle) {
          if (valid && !this.hiveMarkerCircle.getGeometry().intersectsCoordinate(fromLonLat(next, EPSG))) {
            valid = false;
           return '存在不位于站定半径内的点！'
          }
        }
      }
      return null;
    },

    // 判断lngLat和最后的点是否距离匹配
    checkDistanceWithLast(olLngLat, _this) {
      let target;
      let errorMsg = '航点与上一个航点距离过大！';
      if (missionPathMarkers.length === 0) {
        // 和机库做比较
        if (_this.hiveMarker) {
          target = _this.hiveMarker.lngLat_ol;
          errorMsg = '该航点与机库位置距离过大！';
        }
      } else {
        // 和最后一个点作比较
        target = missionPathMarkers[missionPathMarkers.length - 1].lngLat_ol;
      }
      if (target) {
        const matched = _this.isDistanceMatch(olLngLat, target);
        if (!matched) {
          _this.$emit('errorMsg', errorMsg);
          return false;
        }
      }
      return true;
    },

    // 设置地图点击模式
    setMapClickMode(mode) {
      this.mapClickMode = mode;
      if (!this.mapClickMode || this.mapClickMode === CLICKMODE.none) {
        this.removeModifyInteraction();
      } else {
        this.initModifyInteraction();
      }
    },

    // 初始化图层
    initmapVector() {
      // 航线折线图层
      this.hiveAndPathVector = new VectorLayer({
        title: '机库图层',
        zIndex: 200,
        source: new Vector({
          features: []
        })
      });

      this.drawVector = new VectorLayer({
        source: new Vector(),
        style: new Style({
          fill: new Fill({
            color: DRAW_STYLE.fillColor
          }),
          stroke: new Stroke({
            color: DRAW_STYLE.strokeColor,
            width: DRAW_STYLE.strokeWidth
          }),
          image: new Circle({
            radius: DRAW_STYLE.circleRadius,
            fill: new Fill({
              color: DRAW_STYLE.circleFillColor
            })
          })
        })
      });
      this.hiveAndPathVector.setMap(this.map);
      this.drawVector.setMap(this.map);

      // 轨迹图层：realtrail,uavtrail
      this.trailVector = new VectorLayer({
        title: '轨迹图层',
        zIndex: 199,
        source: new Vector({
          features: []
        })
      });
      this.trailVector.setMap(this.map);
      // 实时轨迹图层：realtrail
      this.trailRealTimeVector = new VectorLayer({
        title: '实时轨迹图层',
        zIndex: 202,
        source: new Vector({
          features: []
        })
      });
      this.trailRealTimeVector.setMap(this.map);

      // 交互的图层：uav,uavgimbal,missionPath点
      this.mapVector = new VectorLayer({
        title: '静态图层',
        zIndex: 203,
        source: new Vector({
          features: []
        })
      });
      this.mapVector.setMap(this.map);

      // 禁飞区自定义图层
      this.layerVector = new VectorLayer({
        title: '自定义禁飞区',
        source: new Vector({
          features: []
        })
      });
      this.layerVector.setMap(this.map);
    },

    fitMissionPath() {
      if (this.missionPath) {
        this.map.getView().fit(this.missionPath.getGeometry(), {
          duration: 500,
          padding: [60, 60, 60, 60]
        });
      }
    },

    // 路网图层
    setRouteNetLayerVisible(visible) {
      this.routeNetLayer.setVisible(visible);
    },
    // 设置卫星图层是否显示
    setSatelliteLayerVisible(visible) {
      this.satelliteLayer.setVisible(visible);
    },
    // 设置标注图层是否显示
    setMarkLayerVisible(visible) {
      this.markLayer.setVisible(visible);
    },
    // 设置自建图层是否显示
    setPrivateLayerVisible(visible) {
      if (this.privateLayer) {
        this.privateLayer.setVisible(visible);
      }
    },
    // 添加机库
    addHiveMarker(siteMode, hiveModel, lng, lat, panTo) {
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      this.removeHiver();
      this.hiveMarker = new Feature(new Point(fromLonLat(olLngLat, EPSG)));
      const style = this.getHiveStyleBysitemModeAndHivType(siteMode, hiveModel, false);
      this.hiveMarker.lngLat_ol = olLngLat;
      this.hiveMarker.lngLat = [lng, lat];
      this.hiveMarker.siteMode = siteMode;
      this.hiveMarker.hiveModel = hiveModel;
      this.hiveMarker.setStyle(style);
      this.hiveAndPathVector.getSource().addFeature(this.hiveMarker);
      if (panTo) {
        // this.map.getView().fit(this.hiveMarker.getGeometry())
        console.log(panTo);
        this.panTo(lng, lat);
      }
    },

    // 添加终点机库
    addEndHiveMarker(siteMode, hiveModel, lng, lat, hihe) {
      console.log(siteMode, hiveModel);
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      this.removeEndHiver();
      this.endHiveMarker = new Feature(new Point(fromLonLat(olLngLat, EPSG)));
      this.endHiveMarker.siteMode = siteMode;
      this.endHiveMarker.hiveModel = hiveModel;
      let style = null;
      if (!hihe) {
        style = this.getHiveStyleBysitemModeAndHivType(siteMode, hiveModel, false);
      }
      this.endHiveMarker.lngLat_ol = olLngLat;
      this.endHiveMarker.setStyle(style);
      this.hiveAndPathVector.getSource().addFeature(this.endHiveMarker);
    },
    // 隐藏降落机库
    hideEndHiveMarker(show) {
      if (!this.endHiveMarker) return false;
      if (show) {
        this.endHiveMarker.setStyle(this.getHiveStyleBysitemModeAndHivType(this.endHiveMarker.siteMode, this.endHiveMarker.hiveModel, false));
      } else {
        this.endHiveMarker.setStyle(null);
      }
    },

    // 移除终点机库
    removeHiver() {
      if (this.hiveMarker) this.hiveAndPathVector.getSource().removeFeature(this.hiveMarker);
      this.hiveMarker = null;
    },

    removeEndHiver() {
      if (this.endHiveMarker) this.hiveAndPathVector.getSource().removeFeature(this.endHiveMarker);
      this.endHiveMarker = null;
    },

    // 绘制机库的作用范围
    addHiveCircle(lng, lat, radius) {
      // this.removeHiveCicle()
      let radi = radius || CIRCLE.radius;
      radi = this.getRadius(radi);
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      if (this.hiveMarkerCircle) {
        this.hiveMarkerCircle.getGeometry().setCoordinates(fromLonLat(olLngLat), EPSG);
      } else {
        this.hiveMarkerCircle = new Feature({
          geometry: new CircleGeom(fromLonLat(olLngLat), radi),
          name: '机库范围'
        });
        const fill = new Fill({
          color: CIRCLE.fill
        });
        const stroke = new Stroke({
          color: CIRCLE.stroke,
          width: CIRCLE.border
        });
        const style = new Style({
          fill,
          stroke,
          zIndex: 2
        });
        this.hiveMarkerCircle.setStyle(style);
        this.hiveAndPathVector.getSource().addFeature(this.hiveMarkerCircle);
      }
    },

    removeHiveCicle() {
      if (this.hiveMarkerCircle) this.hiveAndPathVector.getSource().removeFeature(this.hiveMarkerCircle);
      this.hiveMarkerCircle = null;
    },

    // 根据ID删除无人机图标
    removeUavMarkerFeatureById(id) {
      if (this.uavMarkerMap.has(id)) {
        this.mapVector.getSource().removeFeature(this.uavMarkerMap.get(id));
        this.uavMarkerMap.delete(id);
      }
    },

    // 根据ID删除无人机图标
    removeUavMarkerById(id) {
      if (this.uavMarkerMap.has(id)) {
        this.map.removeOverlay(this.uavMarkerMap.get(id));
        this.uavMarkerMap.delete(id);
        let dom = document.getElementById(id);
        if (dom && dom.parentNode) {
          dom.parentNode.removeChild(dom);
        }
      }
    },

    removeUavAll() {
      const that = this;
      this.uavMarkerMap.forEach(function (value, key) {
        that.map.removeOverlay(that.uavMarkerMap.get(key));
      });
      that.uavMarkerMap.clear();
      that.uavMarkerMap.clear();
    },
    // 根据ID删除云台图标
    removeGimbalMarkerById(id) {
      if (this.gimbalMarkerMap.has(id)) {
        this.mapVector.getSource().removeFeature(this.gimbalMarkerMap.get(id));
        this.gimbalMarkerMap.delete(id);
      }
    },

    // 添加,更新无人机位置和角度
    setUavMarkerFearture(id, lng, lat, angle) {
      // eslint-disable-next-line no-param-reassign
      angle = angle || 0;
      const radians = parseFloat(((Math.PI * angle) / 180).toFixed(2));
      if (this.uavMarkerMap.has(id)) {
        const uavMarker = this.uavMarkerMap.get(id);
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        uavMarker.getGeometry().setCoordinates(fromLonLat(olLngLat, EPSG));
        uavMarker.getStyle().getImage().setRotation(radians);
      } else {
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        const uavMarker = new Feature(new Point(fromLonLat(olLngLat, EPSG)));
        const style = new Style({
          image: new Icon({
            rotation: radians,
            scale: 0.7,
            anchor: [0.5, 0.5],
            src: this.logo.uav
          }),
          zIndex: 999
        });
        uavMarker.setStyle(style);
        this.mapVector.getSource().addFeature(uavMarker);
        this.uavMarkerMap.set(id, uavMarker);
      }
    },

    // 添加,更新无人机位置和角度 gif图
    setUavMarker(id, lng, lat, angle) {
      // eslint-disable-next-line no-param-reassign
      angle = angle || 0;
      let transform = 'transform:' + 'rotate(' + angle + 'deg)';
      let markerContent = null;
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      if (this.uavMarkerMap.has(id)) {
        markerContent = document.getElementById(id);
        const uavMarker = this.uavMarkerMap.get(id);
        uavMarker.setPosition(fromLonLat(olLngLat, EPSG));
      } else {
        //添加
        let marker = new Overlay({
          position: fromLonLat(olLngLat, EPSG),
          positioning: 'center-center',
          stopEvent: false,
          autoPan: false
        });
        // 自定义点标记内容
        markerContent = document.createElement('img');
        markerContent.setAttribute('src', this.logo.uavGif);
        markerContent.setAttribute('id', id);
        const domCheck = document.getElementById('');
        markerContent.setAttribute('class', 'uav');
        marker.setElement(markerContent); // 更新点标记内容
        this.map.addOverlay(marker);
        this.uavMarkerMap.set(id, marker);
      }
      markerContent.setAttribute('style', transform);
    },

    // 设置云台的位置和角度
    setGimbalMarker(id, lng, lat, angle) {
      // eslint-disable-next-line no-param-reassign
      angle = angle || 0;
      const radians = parseFloat(((Math.PI * angle) / 180).toFixed(2));
      if (this.gimbalMarkerMap.has(id)) {
        const gimbalMarker = this.gimbalMarkerMap.get(id);
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        gimbalMarker.getGeometry().setCoordinates(fromLonLat(olLngLat, EPSG));
        gimbalMarker.getStyle().getImage().setRotation(radians);
      } else {
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        const gimbalMarker = new Feature(new Point(fromLonLat(olLngLat, EPSG)));
        const style = new Style({
          image: new Icon({
            rotation: radians,
            scale: 0.7,
            anchor: [0.5, 1.5],
            src: this.logo.gimbal
          }),
          zIndex: 999
        });
        gimbalMarker.setStyle(style);
        this.mapVector.getSource().addFeature(gimbalMarker);
        this.gimbalMarkerMap.set(id, gimbalMarker);
      }
    },

    initUavPath(lng, lat) {
      const trailStyle = new Style({
        stroke: new Stroke({
          color: TRAIL.color,
          width: TRAIL.width
        }),
        image: new Circle({
          radius: TRAIL.radius,
          fill: new Fill({
            color: TRAIL.fillColor
          })
        }),
        zIndex: 9999
      });
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      const path = [fromLonLat(olLngLat, EPSG)];
      this.uavTrail = new Feature(new LineString(path));
      this.uavTrail.setStyle(trailStyle);
      this.trailVector.getSource().addFeature(this.uavTrail);
    },

    clearUavPath() {
      if (this.uavTrail) {
        this.trailVector.getSource().removeFeature(this.uavTrail);
      }
      this.uavTrail = null;
    },

    clearMissionPath() {
      if (this.missionPath) {
        this.hiveAndPathVector.getSource().removeFeature(this.missionPath);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const m of missionPathMarkers) {
        this.mapVector.getSource().removeFeature(m);
      }
      if (this.missionModify) {
        this.map.removeInteraction(this.missionModify);
      }
      this.missionModify = null;
      this.missionPath = null;
      missionPathMarkers = [];
    },
    clearRealTimeTrail() {
      if (this.realTimeTrail) {
        this.trailRealTimeVector.getSource().removeFeature(this.realTimeTrail);
      }
      this.realTimeTrail = null;
    },
    clearUavTrail() {
      if (this.uavTrail) {
        this.trailVector.getSource().removeFeature(this.uavTrail);
      }
      this.uavTrail = null;
    },
    initRealTimeTrail(lng, lat) {
      const style = new Style({
        stroke: new Stroke({
          color: TRAIL.realColor,
          width: TRAIL.width
        }),
        zIndex: 4
      });
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      const path = [fromLonLat(olLngLat, EPSG)];
      this.realTimeTrail = new Feature(new LineString(path));
      this.realTimeTrail.setStyle(style);
      this.trailRealTimeVector.getSource().addFeature(this.realTimeTrail);
    },

    updateRealTimePath() {
      let path = [];
      // eslint-disable-next-line prefer-rest-params
      if (arguments.length === 1 && Array.isArray(arguments[0])) {
        // 一系列路径点
        // eslint-disable-next-line prefer-rest-params
        const records = arguments[0];
        let index = 0;
        if (!this.realTimeTrail) {
          index = 1;
          this.initRealTimeTrail(records[0].longitude, records[0].latitude);
        }
        path = this.realTimeTrail.getGeometry().getCoordinates();
        if (records.length > 1 + index) {
          for (let i = 0 + index; i < records.length; i += 1) {
            const lastLng = toLonLat(path[path.length - 1], EPSG)[0];
            const lastLat = toLonLat(path[path.length - 1], EPSG)[1];
            const olLngLat = coordtransform.wgs84togcj02(records[i].longitude, records[i].latitude);
            const distance = getDistance([lastLng, lastLat], olLngLat);
            if (distance >= TRAIL.minDistance) {
              path.push(fromLonLat(olLngLat, EPSG));
            }
          }
        }
        this.realTimeTrail.getGeometry().setCoordinates(path);
        // eslint-disable-next-line prefer-rest-params
      } else if (arguments.length === 2 && typeof arguments[0] === 'number' && typeof arguments[1] === 'number') {
        // 传入经纬度参数
        // eslint-disable-next-line prefer-rest-params
        const lng = arguments[0];
        // eslint-disable-next-line prefer-rest-params
        const lat = arguments[1];
        if (!this.realTimeTrail) {
          this.initRealTimeTrail(lng, lat);
        }
        path = this.realTimeTrail.getGeometry().getCoordinates();
        // 转换
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        if (path.length > 1) {
          const lastLng = toLonLat(path[path.length - 1], EPSG)[0];
          const lastLat = toLonLat(path[path.length - 1], EPSG)[1];
          const distance = getDistance([lastLng, lastLat], olLngLat);
          if (distance >= TRAIL.minDistance) {
            path.push(fromLonLat(olLngLat, EPSG));
          }
        } else {
          path.push(fromLonLat(olLngLat, EPSG));
        }
        this.realTimeTrail.getGeometry().setCoordinates(path);
      }
    },

    setRealTimePath(records) {
      if (!this.realTimeTrail) {
        this.initRealTimeTrail(records[0].longitude, records[0].latitude);
      }
      const olLngLat1 = coordtransform.wgs84togcj02(records[0].longitude, records[0].latitude);
      const path = [fromLonLat(olLngLat1, EPSG)];
      if (records.length > 0) {
        for (let i = 0; i < records.length; i += 1) {
          const lastLng = toLonLat(path[path.length - 1], EPSG)[0];
          const lastLat = toLonLat(path[path.length - 1], EPSG)[1];
          const olLngLat = coordtransform.wgs84togcj02(records[i].longitude, records[i].latitude);
          const distance = getDistance([lastLng, lastLat], olLngLat);
          if (distance >= TRAIL.minDistance) {
            path.push(fromLonLat(olLngLat, EPSG));
          }
        }
      }
      this.realTimeTrail.getGeometry().setCoordinates(path);
    },

    //新增航迹线，dash：是否是虚线
    addRealTimePathByRecords(records, dash) {
      console.log(records, dash);
      if (records.length > 1) {
        const olLngLat1 = coordtransform.wgs84togcj02(records[0].longitude, records[0].latitude);
        let path = [fromLonLat(olLngLat1, EPSG)];
        for (let i = 0; i < records.length; i += 1) {
          const lastLng = toLonLat(path[path.length - 1], EPSG)[0];
          const lastLat = toLonLat(path[path.length - 1], EPSG)[1];
          const olLngLat = coordtransform.wgs84togcj02(records[i].longitude, records[i].latitude);
          const distance = getDistance([lastLng, lastLat], olLngLat);
          if (distance >= TRAIL.minDistance) {
            path.push(fromLonLat(olLngLat, EPSG));
          }
        }
        const style = new Style({
          stroke: new Stroke({
            color: dash ? TRAIL.realOpacityColor : TRAIL.realColor,
            width: TRAIL.width,
            lineDash: [dash ? 20 : 0]
          }),
          zIndex: 4
        });
        let line = new Feature(new LineString(path));
        line.setStyle(style);
        line.isReal = !dash;
        this.trailRealTimeVector.getSource().addFeature(line);
        this.realTimeTrails.push(line);
        this.map.getView().fit(line.getGeometry(), {
          duration: 500,
          padding: [60, 60, 60, 60]
        });
      }
    },

    // 更新最后一条航迹线条(最后一条一定是实时轨迹，非模拟轨迹)
    updateLastRealTimePathByLngLat(lng, lat) {
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);
      let coors = fromLonLat(olLngLat, EPSG);
      let path = [];
      if (this.realTimeTrails.length > 0 && this.realTimeTrails[this.realTimeTrails.length - 1].isReal) {
        let lastLine = this.realTimeTrails[this.realTimeTrails.length - 1];
        path = lastLine.getGeometry().getCoordinates();
        path.push(coors);
        this.realTimeTrails[this.realTimeTrails.length - 1].getGeometry().setCoordinates(path);
      } else {
        path.push(coors);
        let line = new Feature(new LineString(path));
        const style = new Style({
          stroke: new Stroke({
            color: TRAIL.realColor,
            width: TRAIL.width,
            lineDash: [0]
          }),
          zIndex: 4
        });
        line.setStyle(style);
        line.isReal = true;
        this.trailRealTimeVector.getSource().addFeature(line);
        this.realTimeTrails.push(line);
      }
    },

    clearRealTimeTrails() {
      this.realTimeTrails.forEach(line => {
        this.trailRealTimeVector.getSource().removeFeature(line);
      });
      this.realTimeTrails = [];
    },

    // 地图中心点设置,参数为1个时候为无人机id
    panTo(lng, lat) {
      if (lng && lat) {
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        this.map.getView().setCenter(fromLonLat(olLngLat, EPSG));
      } else if (lng && this.uavMarkerMap.has(lng)) {
        const uav = this.uavMarkerMap.get(lng);
        this.map.getView().setCenter(uav.getGeometry().getCoordinates(), { duration: 500 });
      }
    },
    // 更新无人机轨迹
    updateUavPath() {
      let path = [];
      // eslint-disable-next-line prefer-rest-params
      if (arguments.length === 1 && Array.isArray(arguments[0])) {
        // 一系列路径点
        // eslint-disable-next-line prefer-rest-params
        const records = arguments[0];
        let index = 0;
        if (!this.uavTrail) {
          index = 1;
          this.initUavPath(records[0].longitude, records[0].latitude);
        }
        path = this.uavTrail.getGeometry().getCoordinates();
        if (records.length > 1 + index) {
          for (let i = 0 + index; i < records.length; i += 1) {
            const lastLng = toLonLat(path[path.length - 1], EPSG)[0];
            const lastLat = toLonLat(path[path.length - 1], EPSG)[1];
            const olLngLat = coordtransform.wgs84togcj02(records[i].longitude, records[i].latitude);
            const distance = getDistance([lastLng, lastLat], olLngLat);
            if (distance >= TRAIL.minDistance) {
              path.push(fromLonLat(olLngLat, EPSG));
            }
          }
        }
        this.uavTrail.getGeometry().setCoordinates(path);
        // eslint-disable-next-line prefer-rest-params
      } else if (arguments.length === 2 && typeof arguments[0] === 'number' && typeof arguments[1] === 'number') {
        // 传入经纬度参数
        // eslint-disable-next-line prefer-rest-params
        const lng = arguments[0];
        // eslint-disable-next-line prefer-rest-params
        const lat = arguments[1];
        if (!this.uavTrail) {
          this.initUavPath(lng, lat);
        }
        path = this.uavTrail.getGeometry().getCoordinates();
        // 转换
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        if (path.length > 1) {
          const lastLng = toLonLat(path[path.length - 1], EPSG)[0];
          const lastLat = toLonLat(path[path.length - 1], EPSG)[1];
          const distance = getDistance([lastLng, lastLat], olLngLat);
          if (distance >= TRAIL.minDistance) {
            path.push(fromLonLat(olLngLat, EPSG));
          }
        } else {
          path.push(fromLonLat(olLngLat, EPSG));
        }
        this.uavTrail.getGeometry().setCoordinates(path);
      }
      this.map.getView().fit(this.uavTrail.getGeometry(), {
        duration: 500,
        padding: [60, 60, 60, 60]
      });
    },

    // 航点信息显示
    showInfoWindow(marker) {
      let show = true;
      // eslint-disable-next-line prefer-rest-params
      if (arguments.length === 2 && !arguments[1]) show = false;
      if (show) {
        if (!this.contextmenu_uav) {
          const contextMenuUav = new Overlay({
            element: document.getElementsByClassName('contextmenu_uav')[0],
            offset: [25, -30],
            positioning: 'top-left',
            stopEvent: false
          });
          this.contextmenu_uav = contextMenuUav;
        }
        this.contextmenu_uav.setPosition(marker.getGeometry().getCoordinates());
        this.map.addOverlay(this.contextmenu_uav);
      } else {
        this.selectIndex = -1;
      }
    },

    // 显示机库信息窗口
    showHiveWindow(marker) {
      if (!this.contextmenu_hive) {
        const menu = new Overlay({
          element: document.getElementsByClassName('contextmenu_hive')[0],
          offset: [25, -30],
          positioning: 'top-left',
          stopEvent: false
        });
        this.contextmenu_hive = menu;
        this.$emit('siteID', marker.id);
      }
      this.contextmenu_hive.setPosition(marker.getGeometry().getCoordinates());
      this.map.addOverlay(this.contextmenu_hive);
      this.selectedHiveMarker = marker;
    },
    // 初始化任务航线
    initMissionPath(path) {
      this.missionPath = new Feature(new LineString(path));
      this.missionPath.setStyle(
        new Style({
          stroke: new Stroke({
            color: ROUTE.color,
            width: ROUTE.width
          }),
          zIndex: 1
        })
      );
      this.hiveAndPathVector.getSource().addFeature(this.missionPath);
    },

    initPolygon(coordinates, type) {
      let fillColor;
      let strokeColor;
      switch (type) {
        case POLYGON.fobidden.type:
          fillColor = POLYGON.fobidden.fill;
          strokeColor = POLYGON.fobidden.stroke;
          break;
        default:
          fillColor = POLYGON.fobidden.fill;
          strokeColor = POLYGON.fobidden.stroke;
      }
      this.polygon = new Feature(new Polygon(coordinates));
      this.polygon.setStyle(
        new Style({
          fill: new Fill({
            color: fillColor
          }),
          stroke: new Stroke({
            color: strokeColor,
            width: POLYGON.stroke
          })
        })
      );
      this.hiveAndPathVector.getSource().addFeature(this.polygon);
    },

    // 添加任务航线
    setMissionPath(pts, type, hivePos, endHive) {
      if (this.missionPath) {
        this.removeMissionPath();
      }
      const path = [];
      for (let i = 0; i < pts.length; i += 1) {
        const lngLat = [pts[i].longitude, pts[i].latitude];
        const olLngLat = coordtransform.wgs84togcj02(pts[i].longitude, pts[i].latitude);
        path.push(fromLonLat(olLngLat, EPSG));
        const m = new Feature(new Point(fromLonLat(olLngLat, EPSG)));
        m.index = i;
        m.longitude = pts[i].longitude;
        m.latitude = pts[i].latitude;
        m.altitude = pts[i].altitude ? pts[i].altitude : 0;
        m.gimbalPitch = pts[i].gimbalPitch ? pts[i].gimbalPitch : 0;
        m.actions = pts[i].actions ? pts[i].actions : null;
        m.type = type || CLICKMODE.mission;
        m.lngLat = lngLat;
        m.lngLat_ol = olLngLat;
        const style = this.getMissionPathMarkerStyle(m.index);
        m.setStyle(style);
        this.mapVector.getSource().addFeature(m);
        missionPathMarkers.push(m);
      }
      if (hivePos) {
        const hivePosolLngLat = coordtransform.wgs84togcj02(hivePos[0], hivePos[1]);
        if (endHive) {
          path.pop(fromLonLat(hivePosolLngLat, EPSG));
        } else {
          path.unshift(fromLonLat(hivePosolLngLat, EPSG));
        }
      }
      this.initMissionPath(path);
      const distance = this.getMissionPathTotalLength();
      this.$emit('distanceChange', distance);
    },

    // 清除降落机会虚线
    removeDashPath() {
      if (this.dashPath) this.hiveAndPathVector.getSource().removeFeature(this.dashPath);
      this.dashPath = null;
    },

    addEndHiveToMissionPath() {
      if (this.endHiveMarker) {
        const path = [];
        const endHiveMarkercoor = this.endHiveMarker.getGeometry().getCoordinates();
        path.push(endHiveMarkercoor);
        if (missionPathMarkers.length > 0) {
          const coordinates = missionPathMarkers[missionPathMarkers.length - 1].getGeometry().getCoordinates();
          path.push(coordinates);
        } else if (this.hiveMarker) {
          const hiveMarkercoor = this.hiveMarker.getGeometry().getCoordinates();
          path.push(hiveMarkercoor);
        }
        if (path.length > 1) {
          if (this.dashPath) {
            this.dashPath.getGeometry().setCoordinates(path);
          } else {
            this.dashPath = new Feature(new LineString(path));
            this.dashPath.setStyle(
              new Style({
                stroke: new Stroke({
                  color: ROUTE.dashcolor,
                  width: ROUTE.width,
                  lineDash: [15]
                }),
                zIndex: 1
              })
            );
            this.hiveAndPathVector.getSource().addFeature(this.dashPath);
          }
        }
      }

      const distance = this.getMissionPathTotalLength();
      this.$emit('distanceChange', distance);
    },

    // 获取任务航线点的样式
    getMissionPathMarkerStyle(index, hightlight) {
      if (hightlight) {
        return new Style({
          text: new Text({
            font: ROUTE.pointSelected.font,
            fill: new Fill({
              color: ROUTE.pointSelected.fillColor
            }),
            text: `${index + 1}`,
            padding: ROUTE.point.fontPadding
          }),
          image: new Circle({
            radius: ROUTE.radius,
            stroke: new Stroke({
              color: ROUTE.point.borderColor,
              width: ROUTE.point.border
            }),
            fill: new Fill({
              color: ROUTE.pointSelected.bgFillColor
            })
          }),
          zIndex: 2
        });
      }
      return new Style({
        text: new Text({
          font: ROUTE.point.font,
          fill: new Fill({
            color: ROUTE.point.fillColor
          }),
          text: `${index + 1}`,
          padding: ROUTE.point.fontPadding,
          offsetX: 0,
          offsetY: 0
        }),
        image: new Circle({
          radius: ROUTE.radius,
          stroke: new Stroke({
            color: ROUTE.point.borderColor,
            width: ROUTE.point.border
          }),
          fill: new Fill({
            color: ROUTE.point.bgFillColor
          })
        }),
        zIndex: index + 1
      });
    },

    // 判断两点距离是否 小于等于 ROUTE.maxIntervalMeter
    isDistanceMatch(arr1, arr2, distanceTarget) {
      const dis = distanceTarget || ROUTE.maxIntervalMeter;
      let distance = 0;
      try {
        distance = getDistance(arr1, arr2);
      } catch (e) {
        return false;
      }
      if (distance <= dis) {
        return true;
      }
      return false;
    },

    // 计算两点之间对的距离
    calculateDistanceByPts(arr1, arr2) {
      const olarr1 = coordtransform.gcj02towgs84(arr1[0], arr1[1]);
      const olarr2 = coordtransform.gcj02towgs84(arr2[0], arr2[1]);
      let dis = getDistance(olarr1, olarr2);
      dis = parseFloat(dis.toFixed(2));
      return dis;
    },

    // 将机库和任务航信连线
    addHivePosToPath(lng, lat) {
      if (this.missionPath) {
        const olLngLat = coordtransform.wgs84togcj02(lng, lat);
        const coordinates = this.missionPath.getGeometry().getCoordinates();
        coordinates.unshift(fromLonLat(olLngLat, EPSG));
        this.missionPath.getGeometry().setCoordinates(coordinates);
      }
    },

    // 设置某个航线的航点选中高亮
    setmarkerHighlight(marker, nohighlight) {
      let style;
      if (!nohighlight) {
        // eslint-disable-next-line no-param-reassign
        marker.highlight = true;
        style = this.getMissionPathMarkerStyle(marker.index, true);
      } else {
        // eslint-disable-next-line no-param-reassign
        marker.highlight = false;
        style = this.getMissionPathMarkerStyle(marker.index);
      }
      marker.setStyle(style);
    },

    // 任务规划，carsel 切换时方向触发
    selectOnePoint(index) {
      this.stopMarkersHighLight();
      if (index >= 0 && missionPathMarkers[index]) {
        this.nextMarkerPreIndex = index;
        this.setmarkerHighlight(missionPathMarkers[index]);
      }
    },

    // 取消高亮的航点
    stopMarkersHighLight() {
      if (missionPathMarkers.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const marker of missionPathMarkers) {
          if (marker.highlight) {
            this.setmarkerHighlight(marker, true);
          }
        }
      }
      if (this.selectIndex >= 0 && missionPathMarkers[this.selectIndex]) {
        this.showInfoWindow(missionPathMarkers[this.selectIndex], false);
      }
      this.selectIndex = -1;
      //  this.nextMarkerPreIndex = -1;
    },

    // 设置航线是否可编辑
    setMissionPathEditAble(editAble) {
      if (editAble) {
        missionPathMarkers.forEach(nmarker => {
          // eslint-disable-next-line no-param-reassign
          nmarker.type = this.mapClickMode;
        });
        this.initModifyInteraction();
      } else {
        this.removeModifyInteraction();
      }
    },

    // 根据所有航点重新绘制当前的航线,marker数量发生变化需要重新设置index
    resetMissionPathByMarkers() {
      const path = [];
      if (this.hiveMarker) {
        path.push(this.hiveMarker.getGeometry().getCoordinates());
      }
      for (let i = 0; i < missionPathMarkers.length; i += 1) {
        const item = missionPathMarkers[i];
        item.index = i;
        item.setId(missionmarkerIdPre + i);
        item
          .getStyle()
          .getText()
          .setText(`${item.index + 1}`);
        path.push(fromLonLat(item.lngLat_ol, EPSG));
      }
      if (this.missionPath) {
        this.missionPath.getGeometry().setCoordinates(path);
      } else {
        this.initMissionPath(path);
      }

      this.addEndHiveToMissionPath();
    },
    // 重置多边形
    resetPolygonByMarkers() {
      const path = this.getPolygonPath(missionPathMarkers);
      if (this.polygon) {
        this.polygon.getGeometry().setCoordinates(path);
      } else {
        this.initPolygon(path);
      }
    },

    // 获取航线总长（包括机库）
    getMissionPathTotalLength(_this) {
      // eslint-disable-next-line no-param-reassign
      _this = _this || this;
      let distance = 0;
      if (missionPathMarkers.length > 0) {
        if (_this.hiveMarker) {
          distance = getDistance(_this.hiveMarker.lngLat_ol, missionPathMarkers[0].lngLat_ol);
        }
        if (_this.endHiveMarker) {
          distance += getDistance(_this.endHiveMarker.lngLat_ol, missionPathMarkers[missionPathMarkers.length - 1].lngLat_ol);
        }
        if (missionPathMarkers.length > 1) {
          for (let i = 1; i < missionPathMarkers.length; i += 1) {
            distance += getDistance(missionPathMarkers[i - 1].lngLat_ol, missionPathMarkers[i].lngLat_ol);
          }
        }
      }
      distance = parseFloat(distance.toFixed(1));
      return distance;
    },

    getPolygonPath(arr) {
      const path = [];
      for (let i = 0; i < arr.length; i += 1) {
        const item = arr[i];
        item.index = i;
        path.push(arr[i].getGeometry().getCoordinates());
      }
      path.push(arr[0].getGeometry().getCoordinates());
      return [path];
    },

    // 根据下标设置某个航点经纬度(非地图交互，用做输入框触发等)
    setMarkerPostionAndPath(index, lng, lat) {
      const m = missionPathMarkers[index];
      const olLngLat = coordtransform.wgs84togcj02(lng, lat);

      // eslint-disable-next-line camelcase
      const beforeLngLat_ol = m.lngLat_ol;
      m.lngLat_ol = olLngLat;
      const distanceAllow = this.checkDistanceByIndex(index, this);
      // 判断是否在圆外
      let circleAllow = false;

      if (this.hiveMarkerCircle) {
        if (this.hiveMarkerCircle.getGeometry().intersectsCoordinate(m.getGeometry().getCoordinates())) {
          circleAllow = true;
        }
      } else {
        circleAllow = true;
      }

      if (!circleAllow) {
        this.$emit('errorMsg', '超出机库区域范围！');
      }
      if (distanceAllow && circleAllow) {
        m.lngLat_ol = olLngLat;
        m.getGeometry().setCoordinates(fromLonLat(olLngLat, EPSG));
        this.resetMissionPathByMarkers();
        const distance = this.getMissionPathTotalLength(this);
        this.$emit('distanceChange', distance);
      } else {
        // eslint-disable-next-line camelcase
        m.lngLat_ol = beforeLngLat_ol;
        const longitude = parseFloat(m.lngLat_ol[0].toFixed(7));
        const latitude = parseFloat(m.lngLat_ol[1].toFixed(7));
        const obj = {
          index: m.index,
          id: m.getId(),
          longitude,
          latitude,
          distance: this.getMissionPathTotalLength(this)
        };
        this.$emit('changePostion', obj);
      }
    },

    // 删除某个航点
    delPathMarkerByIndex(index) {
      console.log(`删除：${index}`);
      if (missionPathMarkers[index]) {
        this.mapVector.getSource().removeFeature(missionPathMarkers[index]);
        missionPathMarkers.splice(index, 1);
        this.resetMissionPathByMarkers();
      }
      if (index === 0) {
        this.nextMarkerPreIndex = -1;
      }
      const distance = this.getMissionPathTotalLength(this);
      this.$emit('distanceChange', distance);
    },

    // 清除航线
    removeMissionPath() {
      if (this.missionPath) {
        this.hiveAndPathVector.getSource().removeFeature(this.missionPath);
      }
      missionPathMarkers.forEach(element => {
        this.mapVector.getSource().removeFeature(element);
      });
      this.missionPath = null;
      missionPathMarkers = [];
      this.nextMarkerPreIndex = -1;
    },

    removeHives(siteList) {
      for (const site of siteList) {
        let hive = this.hiveAndPathVector.getSource().getFeatureById(site.siteID);
        if (hive) {
          this.hiveAndPathVector.getSource().removeFeature(hive);
        }
      }
    },

    renderHives(siteList, pan) {
      let ids = [];
      for (const site of siteList) {
        ids.push(site.siteID);
        const olLngLat = coordtransform.wgs84togcj02(site.siteLocation[0], site.siteLocation[1]);
        let hiveMarker = new Feature(new Point(fromLonLat(olLngLat, EPSG)));
        hiveMarker.siteMode = site.siteMode;
        hiveMarker.type = this.CLICKMODE.mainHive;
        hiveMarker.siteName = site.siteName;
        hiveMarker.hiveinfo = site.hiveinfo;
        hiveMarker.UAVInfo = site.UAVInfo;
        hiveMarker.hiveType = site.hiveinfo && site.hiveinfo.hiveType ? site.hiveinfo.hiveType : 1;
        hiveMarker.hiveModel = site.hiveinfo && site.hiveinfo.hiveModel ? site.hiveinfo.hiveModel : '未知';
        if (site.UAVInfo && site.UAVInfo.model) {
          hiveMarker.uavModel = site.UAVInfo.model;
        } else {
          hiveMarker.uavModel = '未知';
        }
        // 站点默认的图片
        hiveMarker.pic = this.getPicBysitemModeAndHivType(site.siteMode, hiveMarker.hiveModel);
        const style = this.getHiveStyleBysitemModeAndHivType(hiveMarker.siteMode, hiveMarker.hiveModel, true);
        hiveMarker.lngLat_ol = olLngLat;
        hiveMarker.setStyle(style);
        hiveMarker.setId(site.siteID);
        hiveMarker.id = site.siteID;
        this.hiveAndPathVector.getSource().addFeature(hiveMarker);
      }
      if (pan && siteList.length > 0) {
        let path = [];
        siteList.forEach(site => {
          const olLngLat = coordtransform.wgs84togcj02(site.siteLocation[0], site.siteLocation[1]);
          path.push(fromLonLat(olLngLat, EPSG));
        });
        this.map.getView().fit(new LineString(path), {
          duration: 500,
          padding: [100, 100, 100, 100]
        });
      }
    },
    setHiveOnline(id, online) {
      const m = this.hiveAndPathVector.getSource().getFeatureById(id);
      if (m) {
        const { siteMode } = m;
        const style = this.getHiveStyleBysitemModeAndHivType(siteMode, m.hiveModel, online);
        m.setStyle(style);
      }
    },

    getHiveStyleBysitemModeAndHivType(siteMode, hiveModel, offline) {
      let pic = null;
      pic = this.getHivePicBysitemModeAndHivType(siteMode, hiveModel, offline);
      return new Style({
        image: new Icon({
          scale: 0.7,
          anchor: [0.5, 0.5],
          src: pic
        }),
        zIndex: 3
      });
    },

    gotoHanlerCenter() {
      this.$emit('gotoCenter', this.selectedHiveMarker.id);
    },

    // Draw
    setDraw(type) {
      this.stopDraw();
      const draw = new Draw({
        // POLYGON,CIRCLE
        type
      });
      const that = this;
      draw.type = type;
      draw.on('drawstart', () => {
        if (that.F_Feature) {
          that.hiveAndPathVector.getSource().removeFeature(that.F_Feature);
        }
      });
      draw.on('drawend', e => {
        that.F_Feature = e.feature;
        that.hiveAndPathVector.getSource().addFeature(that.F_Feature);
      });
      this.drawInteraction = draw;
      this.map.addInteraction(draw);
    },

    stopDraw() {
      if (this.drawInteraction) {
        this.map.removeInteraction(this.drawInteraction);
      }
    },

    // 开启临时航点
    startSetTempPt(start) {
      if (start) {
        // 开始临时航点选取
        this.mapClickMode = CLICKMODE.tempmarker;
      } else {
        // 清除临时航点
        // eslint-disable-next-line no-lonely-if
        if (this.mapVector.getSource().getFeatureById(CLICKMODE.tempmarker)) {
          this.mapVector.getSource().removeFeature(this.mapVector.getSource().getFeatureById(CLICKMODE.tempmarker));
        }
      }
    },
    stopTempPtChoose() {
      this.mapClickMode = CLICKMODE.none;
    },

    // 设置临时航点
    changeTmpPt(arr) {
      const marker = this.mapVector.getSource().getFeatureById(CLICKMODE.tempmarker);
      if (marker) {
        marker.getGeometry().setCoordinates(fromLonLat(arr, EPSG));
      } else {
        console.error('no tmp marker !');
      }
    },

    updateLaserPoint(arr, obj) {
      if (arr && arr.length >= 2) {
        // 更新
        let speedText = ''; // `, spd: ${0}m/s`
        if (obj && obj.laserTargetLocation && obj.laserTargetLocation.length >= 2) {
          const { seconds } = obj;
          console.error(obj);
          console.error([arr[0], arr[1]], [obj.laserTargetLocation[0], obj.laserTargetLocation[1]]);
          const dis = getDistance([arr[0], arr[1]], [obj.laserTargetLocation[0], obj.laserTargetLocation[1]]);
          speedText = `, spd: ${(dis / seconds).toFixed(2)}m/s`;
        }
        const olLngLat = coordtransform.wgs84togcj02(arr[0], arr[1]);
        if (this.laserPoint) {
          this.laserPoint.setPosition(fromLonLat(olLngLat, EPSG));
          document.getElementById(laserTmpId.lngLat).innerText = `${arr[0]},${arr[1]}`;
          document.getElementById(laserTmpId.spdHt).innerText = `${arr[3].toFixed(2)}m ${speedText}`;
        } else {
          this.laserPoint = new Overlay({
            position: fromLonLat(olLngLat, EPSG),
            positioning: 'top-left',
            offset: [-25, -25],
            //  offset:FCOW_ROUTE.stastusoffset,
            stopEvent: false
          });

          // 自定义点标记内容
          const markerContent = document.createElement('div');
          markerContent.setAttribute('class', 'laser');
          // 点标记中的图标
          const markerImgDiv = document.createElement('div');
          const markerImg = document.createElement('img');
          markerImg.src = this.ptImg.laser;
          markerImgDiv.appendChild(markerImg);
          const markerlabelDiv = document.createElement('div');
          const markertitle = document.createElement('p');

          const markerPos = document.createElement('p');
          markerPos.setAttribute('id', laserTmpId.lngLat);
          const markerSpd = document.createElement('p');
          markerSpd.setAttribute('id', laserTmpId.spdHt);
          markertitle.innerText = '激光测距目标物：';
          markerSpd.innerText = `${arr[3].toFixed(2)}m ${speedText}`;
          markerlabelDiv.appendChild(markertitle);
          markerlabelDiv.appendChild(markerPos);
          markerlabelDiv.appendChild(markerSpd);
          markerContent.appendChild(markerImgDiv);
          markerContent.appendChild(markerlabelDiv);
          this.laserPoint.setElement(markerContent); // 更新点标记内容

          this.map.addOverlay(this.laserPoint);
        }
      } else {
        // 清除
        // eslint-disable-next-line no-lonely-if
        if (this.laserPoint) {
          this.map.removeOverlay(this.laserPoint);
          this.laserPoint = null;
        }
      }
    },

    activeDraw(active, type) {
      if (this.calcDraw) {
        this.draws[this.calcDraw].setActive(false);
        this.calcDraw = null;
      }
      if (active) {
        this.calcDraw = type;
        if (type === 'LineString') {
          this.drawingLengthText = '单击确定起点';
        }
        this.draws[this.calcDraw].setActive(true);
        this.drawingLengthTextOverlay =
          this.drawingLengthTextOverlay ||
          new Overlay({
            element: document.getElementById('drawingLengthText'),
            offset: [5, 8],
            positioning: 'top-left',
            stopEvent: false,
            className: 'first-label'
          });
        this.map.addOverlay(this.drawingLengthTextOverlay);
      } else {
        this.drawingLengthText = null;
        if (this.drawingLengthTextOverlay) {
          this.map.removeOverlay(this.drawingLengthTextOverlay);
        }
      }
    },

    activeModify(active) {
      this.calcSelect = new Select({});
      this.map.addInteraction(this.calcSelect);
      const selectedFeatures = this.calcSelect.getFeatures();
      this.calcSelect.on('change:active', function () {
        selectedFeatures.forEach(function (each) {
          selectedFeatures.remove(each);
        });
      });

      this.calcModify = new Modify({
        source:this.drawVector.getSource()
      });
      this.map.addInteraction(this.calcModify);
      this.calcSnap =
        this.calcSnap ||
        new Snap({
          source: this.drawVector.getSource()
        });
      this.calcModify && this.calcModify.setActive(active);
    },

    // 绘制几何图形
    startGeometryByType(type) {
      if (!this.calcAvalible) {
        this.initCalcDraw();
      }
      if(!this.calcModify){
       // this.activeModify(true)
      }
      this.activeDraw(true, type);
      this.calcAvalible = true;
    },
    // 清除绘制的几何图形
    clearDrawedGeometries() {
      this.drawVector.getSource().forEachFeature(feature => {
        this.drawVector.getSource().removeFeature(feature);
      });
      this.clearDrawedOverLays();
    },

    initCalcDraw() {
      const that = this;
      this.draws.Point =
        this.draws.Point ||
        new Draw({
          source: this.drawVector.getSource(),
          type: 'Point',
        });

      this.draws.Point.on('drawstart', function (evt) {
        that.drawSketch = evt.feature;
        that.startSketchListener(that.drawSketch);
      });
      this.draws.Point.on('drawend', function (evt) {
        let coor = evt.feature.getGeometry().getCoordinates();
        that.addDrawedOverLay(coor, toLonLat(coor, EPSG)[0].toFixed(6) + ',' + toLonLat(coor, EPSG)[1].toFixed(6));
        that.activeDraw(false);
      });
      this.map.addInteraction(this.draws.Point);
      this.draws.Point.setActive(false);
      this.draws.LineString =
        this.draws.LineString ||
        new Draw({
          source: this.drawVector.getSource(),
          type: 'LineString',
        });
      this.draws.LineString.on('drawstart', function (evt) {
        that.drawSketch = evt.feature;
        that.startSketchListener(that.drawSketch);
      });
      this.draws.LineString.on('drawend', function () {
        that.activeDraw(false);
        that.drawCoordTemp = [];
      });
      this.map.addInteraction(this.draws.LineString);
      this.draws.LineString.setActive(false);
      this.draws.Polygon =
        this.draws.Polygon ||
        new Draw({
          source: this.drawVector.getSource(),
          type: 'Polygon',
        });
      this.draws.Polygon.on('drawstart', function (evt) {
        that.drawSketch = evt.feature;
        that.startSketchListener(that.drawSketch);
      });
      this.draws.Polygon.on('drawend', function (evt) {
        console.error('Polygon drawend');
        that.addDrawedOverLay(evt.feature.getGeometry().getInteriorPoint().getCoordinates());

        that.activeDraw(false);
      });
      this.map.addInteraction(this.draws.Polygon);
      this.draws.Polygon.setActive(false);
      this.draws.Circle =
        this.draws.Circle ||
        new Draw({
          source: this.drawVector.getSource(),
          type: 'Circle',
        });
      this.draws.Circle.on('drawstart', function (evt) {
        that.drawSketch = evt.feature;
        that.startSketchListener(that.drawSketch);
      });
      this.draws.Circle.on('drawend', function (evt) {
        console.error('Circle drawend');
        console.log(evt);
        // 画圆面积标注物
        that.addDrawedOverLay(evt.feature.getGeometry().getCenter());

        that.activeDraw(false);
      });
      this.map.addInteraction(this.draws.Circle);
      this.draws.Circle.setActive(false);
    },

    addDrawedOverLay(position, text) {
      let element = document.createElement('div');
      element.innerText = text || this.drawingLengthText;
      this.$refs.mapContainer.appendChild(element);
      let id = overLayIdPre + new Date().getTime();
      let overlay = new Overlay({
        id,
        position,
        element,
        offset: [5, 8],
        positioning: 'top-left',
        stopEvent: false,
        className: 'first-label'
      });
      this.drawOverlayIds.push(id);
      this.map.addOverlay(overlay);
    },

    clearDrawedOverLays() {
      this.drawOverlayIds.forEach(id => {
        if (this.map.getOverlayById(id)) {
          this.map.removeOverlay(this.map.getOverlayById(id));
        }
      });
      // this.drawOverlayIds = [];
    },
    startSketchListener(sketch) {
      if (this.sketchListener) {
        unByKey(this.sketchListener);
      }
      const that = this;
      this.sketchListener = sketch.getGeometry().on('change', function (evt) {
        console.log(evt);
        sketch.setStyle(that.getSketchStyleByLayerType())
        const geom = evt.target;
        let output;
        console.log(geom);
        if (geom instanceof Polygon) {
          //getInteriorPoint
          output = that.formatArea(geom);
          that.drawingLengthText = output;
          if (that.drawingLengthTextOverlay) {
            that.drawingLengthTextOverlay.setPosition(evt.target.getInteriorPoint().getCoordinates());
          }

        } else if (geom instanceof LineString) {
          output = that.formatLength(geom);
          if (that.drawCoordTemp && that.drawCoordTemp.length != geom.getCoordinates().length) {
            let coord = geom.getCoordinates();
            let coordTarget = coord[coord.length - 2];
            let length = getLength(geom);
            let length2 = getDistance(toLonLat(coord[coord.length - 1], EPSG), toLonLat(coord[coord.length - 2], EPSG));
            let meters = that.formatLengthUnit(parseInt(length - length2));
            that.addDrawedOverLay(coordTarget, meters);
            that.drawCoordTemp = coord;
          }

          that.drawingLengthText = output;
          if (that.drawingLengthTextOverlay) {
            that.drawingLengthTextOverlay.setPosition(evt.target.getLastCoordinate());
          }
        } else if (geom instanceof CircleGeom) {
          let rad = that.fromRadius(geom.getRadius());
          if (that.drawingLengthTextOverlay) {
            that.drawingLengthTextOverlay.setPosition(evt.target.getCenter());
          }
          that.drawingLengthText = that.formatAreaNumber(parseInt(Math.pow(rad, 2) * Math.PI));
        }

        // measureTooltipElement.innerHTML = output;
        // measureTooltip.setPosition(tooltipCoord);
      });
    },
    // 格式化线条长度
    formatLength(line) {
      const length = getLength(line);

      return this.formatLengthUnit(length);
    },

    formatLengthUnit(length = 0) {
      let output = length;
      if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
      } else {
        output = Math.round(length * 100) / 100 + ' ' + 'm';
      }
      return output;
    },

    /**
     * Format area output.
     * @param {Polygon} polygon The polygon.
     * @return {string} Formatted area.
     */
    formatArea(polygon) {
      const area = getArea(polygon);
      return this.formatAreaNumber(area);
    },

    formatAreaNumber(area) {
      let output;
      console.log(area);
      if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km²';
      } else {
        output = Math.round(area * 100) / 100 + ' ' + 'm²';
      }
      return output;
    }
  }
};
</script>
>

<style lang="scss" scoped>
$hilightColor: #36c6fb;
$color-layer-bg: rgba(18, 18, 18, 0.85);
$color-layer: #a1a1a1;

::v-deep .el-collapse-item__content {
  padding-left: 15px;
  padding-bottom: 0;
}
::v-deep .el-collapse-item__arrow {
  transform: rotate(90deg);
}
::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(270deg);
}
::v-deep .el-collapse-item__header {
  color: white;
  background-color: transparent;
  border-bottom: 0;
  border-top: 0;
  position: relative;
  padding-left: 15px;
  &::before {
    content: '';
    width: 2px;
    height: 20px;
    background-color: #36c6fb;
    bottom: 13px;
    left: 0px;
    position: absolute;
  }
}

::v-deep .el-collapse {
  border-bottom: 0;
  border-top: 0;
}
::v-deep .el-collapse-item__wrap {
  background-color: transparent;
  border-bottom: 0;
}
::v-deep .el-switch__label {
  color: white;
}

::v-deep .ol-scale-line {
  left: 50px;
}

::v-deep .uav {
  width: 48px;
}
::v-deep .first-label {
    z-index: 10;
    font-size: 14px;
    color: white;
    background: #36c6fbcc;
    border: 2px solid white;
    border-radius: 5px;
    padding: 4px;
}

::v-deep .del-label {
  cursor: pointer;
  > img {
    width: 25x;
  }
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
  .base-box {
    position: absolute;
    z-index: 400;
    right: 28px;
    bottom: 28px;
    transition: right 0.5s;
    .base-wrapper {
      position: relative;
      .base-layer-box {
        width: 100%;
      }
    }
    .common {
      cursor: pointer;
    }

    img {
      width: 28px;
      &.hilight {
        border-radius: 3px;
        border: 1px solid $hilightColor;
      }
    }
    .layer-box {
      background: $color-layer-bg;
      border: 1px solid #6a6a6a;
      border-radius: 5px;
      padding: 16px 0 10px;
      position: absolute;
      bottom: 35%;
      right: 54px;
      width: 200px;
      .switch-item {
        height: 40px;
        > div {
          width: 50%;
          float: left;
          color: white;
        }
      }
      .title {
        border-left: 2px solid $hilightColor;
        padding-left: 10px;
        color: #ffffff;
      }
      .tool-item {
        height: 20px;
        margin-top: 5px;
        padding: 0 15px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        &.is-drawing {
          color: $hilightColor;
        }
        .spot {
          width: 10px;
          height: 10px;
          background: $hilightColor;
          border-radius: 50%;
          position: absolute;
          right: 15px;
          top: 4px;
        }
        &:hover {
          opacity: 0.8;
          background-color: #0b172f;
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
      .layer-group {
        padding: 6px 0 6px 12px;
        .layer {
          display: block;
          padding: 6px 0;
        }
      }
      // 区域定位样式
      .layer.child {
        padding-left: 30px;
      }
      /deep/.ant-checkbox-wrapper {
        font-size: 14px;
      }
    }
  }
}

::v-deep .ol-zoom {
  top: auto;
  bottom: 0.5em !important;
  left: 0.5em;
}

.contextmenu_uav {
  min-width: 160px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  position: absolute;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;

  .pt-menu {
    > div {
      margin-bottom: 10px;
      word-break: keep-all;
    }
  }
}

.contextmenu_hive {
  width: 300px;
  box-shadow: 0 2px 12px 0 green;
  height: auto;
  background-color: #2d3035;
  color: white;
  display: flex;
  position: absolute;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;

  .pt-menu {
    width: 100%;
    .title {
      box-sizing: border-box;
      padding: 10px;
      background: #22252a;
      text-align: center;
    }

    .button {
      width: 100%;

      > button {
        width: 100%;
      }
    }

    .img {
      height: 210px;
      width: 100%;
      display: flex;

      img {
        max-width: 100%;
        margin: auto;
      }
    }

    .two {
      box-sizing: border-box;
      padding: 10px;
      background: #22252a;
      text-align: center;
      width: 100%;
      display: flex;
      margin-bottom: 10px;

      > div {
        width: 100%;
      }
    }
  }
}

#mousemovetitle {
  color: #1f90ff;
  font-weight: bold;
  font-size: 12px;
}

/deep/ .laser {
  display: flex;
  font-size: 12px;

  > div:first-child {
    width: 30px;
    display: flex;

    * {
      margin: auto;
    }

    img {
      width: 32px;
      height: 32px;
    }
  }

  > div:nth-child(2) {
    border-radius: 3px;
    background-color: #92bdea;
    border: 3px solid #19f928;
    padding: 5px;
    color: white;

    p {
      margin: 0;
      font-size: 12px;
    }
  }
}
</style>
