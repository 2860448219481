import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';
export default {
  data() {
    return {
      logo: {
        hives: {
          hive1: require('@/assets/images/map/hive/hive1.png'),
          hive1offline: require('@/assets/images/map/hive/hive1offline.png'),
          hive3: require('@/assets/images/map/hive/hive3.png'),
          hive3offline: require('@/assets/images/map/hive/hive3offline.png'),
          hive4: require('@/assets/images/map/hive/hive4.png'),
          hive4offline: require('@/assets/images/map/hive/hive4offline.png'),
          hive5: require('@/assets/images/map/hive/hive5.png'),
          hive5offline: require('@/assets/images/map/hive/hive5offline.png'),
          moboile: require('@/assets/images/map/hive/moboile.png'),
          moboileoffline: require('@/assets/images/map/hive/moboileoffline.png'),
          grandHub: require('@/assets/images/map/hive/grandhub.png'),
          grandHuboffline: require('@/assets/images/map/hive/grandhuboffline.png'),
          radioFrequencyBox: require('@/assets/images/map/hive/radioFrequencyBox.png'),
          radioFrequencyBoxoffline: require('@/assets/images/map/hive/radioFrequencyBoxoffline.png'),
          controlleroffline: require('@/assets/images/map/hive/controlleroffline.png'),
          controller: require('@/assets/images/map/hive/controller.png'),
          onboard: require('@/assets/images/map/hive/onboard.png'),
          onboardoffline: require('@/assets/images/map/hive/onboardoffline.png'),

          UH_MK3: require('@/assets/images/map/hive/UH_MK3.png'),
          UH_MK3offline: require('@/assets/images/map/hive/UH_MK3offline.png'),

          UH_MK4: require('@/assets/images/map/hive/UH_MK4.png'),
          UH_MK4offline: require('@/assets/images/map/hive/UH_MK4offline.png'),
          UH_MK4_Pro: require('@/assets/images/map/hive/UH_MK4_Pro.png'),
          UH_MK4_Prooffline: require('@/assets/images/map/hive/UH_MK4_Prooffline.png'),
          UH_MK5: require('@/assets/images/map/hive/UH_MK5.png'),
          UH_MK5offline: require('@/assets/images/map/hive/UH_MK5offline.png'),
          MH_MK3: require('@/assets/images/map/hive/MH_MK3.png'),
          MH_MK3offline: require('@/assets/images/map/hive/MH_MK3offline.png'),
        },
        sites: {
          default: require('@/assets/images/map/site/default.png'),
          computer: require('@/assets/images/map/site/computer.png'),
          MH_MK3: require('@/assets/images/map/site/MH_MK3.png'),
          controller: require('@/assets/images/map/site/controller.png'),
          WK_YU_JK: require('@/assets/images/map/site/WK_YU_JK.png'),
          UH_MK4: require('@/assets/images/map/site/UH_MK4.png'),
          UH_MK4_Pro: require('@/assets/images/map/site/UH_MK4_Pro.png'),
          UH_MK5: require('@/assets/images/map/site/UH_MK5.png'),
          GH: require('@/assets/images/map/site/GH-default.png'),
        },
        uav: require('@/assets/images/map/uav.png'),
        uavGif: require('@/assets/images/map/uav.gif'),
        gimbal: require('@/assets/images/map/gimbal.png'),
      },
      layerCtrl: {
        streetImg: require('@/assets/images/map/layer/satelliteimagery.png'),
        satelliteImg: require('@/assets/images/map/layer/tiles.png'),
        layerSelf: require('@/assets/images/map/layer/selfbuilding.png'),
        routeNetImg: require('@/assets/images/map/layer/net.png'),
      },

      ptImg: {
        sitemarker: require('@/assets/images/map/pt/site.png'), // 站点
        safemarker: require('@/assets/images/map/pt/safe.png'), // 安全点
        landingmarker: require('@/assets/images/map/pt/landing.png'), // 备降点
        default: require('@/assets/images/map/pt/default.png'), // 默认点
        tempmarker: require('@/assets/images/map/pt/tmpPt.png'), // 临时航点
        laser: require('@/assets/images/map/pt/laser.png') // 激光点
      },
    };
  },
  methods: {
    getHivePicBysitemModeAndHivType(siteMode, hiveModel, offline) {

      if (siteMode == SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox || siteMode == SITE_HIVE_TYPE.SITEMODE.radioFrequencyBox) {
        if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK3) {
          if (offline) {
            return this.logo.hives.UH_MK3offline;
          } else {
            return this.logo.hives.UH_MK3;
          }
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK4) {
          if (offline) {
            return this.logo.hives.UH_MK4offline;
          } else {
            return this.logo.hives.UH_MK4;
          }
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK4_Pro) {
          if (offline) {
            return this.logo.hives.UH_MK4_Prooffline;
          } else {
            return this.logo.hives.UH_MK4_Pro;
          }
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK5) {
          if (offline) {
            return this.logo.hives.UH_MK5offline;
          } else {
            return this.logo.hives.UH_MK5;
          }
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.MH_MK3) {
          if (offline) {
            return this.logo.hives.MH_MK3offline;
          } else {
            return this.logo.hives.MH_MK3;
          }
        } else {
          if (offline) {
            return this.logo.hives.hive1offline;
          } else {
            return this.logo.hives.hive1;
          }
        }
      } else if (siteMode == SITE_HIVE_TYPE.SITEMODE.grandHub) {
        if (offline) {
          return this.logo.hives.grandHuboffline;
        } else {
          return this.logo.hives.grandHub;
        }
      } else if (siteMode == SITE_HIVE_TYPE.SITEMODE.uavController) {
        if (offline) {
          return this.logo.hives.controlleroffline;
        } else {
          return this.logo.hives.controller;
        }
      } else if (siteMode == SITE_HIVE_TYPE.SITEMODE.onboard) {
        if (offline) {
          return this.logo.hives.onboardoffline;
        } else {
          return this.logo.hives.onboard;
        }
      } else {
        if (offline) {
          return this.logo.hives.hive3offline;
        } else {
          return this.logo.hives.hive3;
        }
      }

    },

    getPicBysitemModeAndHivType(siteMode, hiveModel) {
      if (siteMode == SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox || siteMode == SITE_HIVE_TYPE.SITEMODE.radioFrequencyBox) {
        if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK3) {
          return this.logo.sites.UH_MK3;
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK4) {
          return this.logo.sites.UH_MK4;
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK4_Pro) {
          return this.logo.sites.UH_MK4_Pro;
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.UH_MK5) {
          return this.logo.sites.UH_MK5;
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.MH_MK3) {
          return this.logo.sites.MH_MK3;
        } else if (hiveModel == SITE_HIVE_TYPE.HIVEMODEL.WK_YU_JK) {
          return this.logo.sites.WK_YU_JK;
        } else {
          return this.logo.sites.default;
        }
      } if (siteMode == SITE_HIVE_TYPE.SITEMODE.grandHub) {
        return this.logo.sites.GH;
      } else if (siteMode == SITE_HIVE_TYPE.SITEMODE.uavController) {
        return this.logo.sites.controller;
      } else if (siteMode == SITE_HIVE_TYPE.SITEMODE.onboard) {
        return this.logo.sites.computer;
      }
      return this.logo.sites.default;
    },

  },
};
